import { KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';

// tslint:disable-next-line:variable-name
export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    return from(this.keycloak.getToken()).pipe(
      mergeMap((token) => {
        const bearerToken = 'Bearer ' + token;
        // Apply the headers
        req = req.clone({
          setHeaders: {
            authorization: bearerToken,
          },
        });

        // Also handle errors globally
        return next.handle(req).pipe(
          tap(
            (x) => x,
            (err) => {
              // Handle this err
              console.error(`Error performing request, status code = ${err.status}`);
            },
          ),
        );
      }),
    );
  }
}
