import { APIClient } from './api-client.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { UserDto } from './models/user-dto.model';

const CACHE_SIZE = 1;
const REQUEST_RETRY_COUNT = 1;
const REQUEST_RETRY_DELAY = 1000;

@Injectable()
export class UsersService {
  private cache$: Observable<UserDto>;
  constructor(private api: APIClient) {}

  getUser(refresh?: boolean): Observable<UserDto> {
    if (!this.cache$ || refresh) {
      this.cache$ = this.requestUsers().pipe(shareReplay(CACHE_SIZE));
    }
    return this.cache$;
  }

  private requestUsers() {
    return this.api.getUsers().pipe(
      map((user) => user.payload),
      catchError((err) => {
        throw Error('Error getting user: ' + JSON.stringify(err));
      }),
      retry({ count: REQUEST_RETRY_COUNT, delay: REQUEST_RETRY_DELAY }),
    );
  }
}
