import { Injectable } from '@angular/core';
import { UsersService } from '../data/users.service';
import { FormItemBase } from '../../components/dynamic-form.component.ts/form-item-base';
import { FieldTypeDto } from '../data';

@Injectable()
export class DefaultValuesService {
  constructor(private user: UsersService) {}

  static hasDefaultOptions = (formField: FormItemBase<any> | FieldTypeDto): boolean => {
    return !!formField.defaultOptions?.value;
  };

  loadDefaultValues = async (formField: FormItemBase<any>) => {
    if (!DefaultValuesService.hasDefaultOptions(formField)) {
      return;
    }

    const defaultOptions = formField.defaultOptions;

    if (defaultOptions.type === 'hardcoded') {
      formField.value = defaultOptions.value;
      return;
    }

    if (defaultOptions.type === 'dynamic') {
      const value = defaultOptions.value;

      switch (value) {
        case 'user':
          const user = await this.user.getUser().toPromise();
          if (formField.controlType === 'multidropdown') {
            formField.value = [user.name];
          } else {
            formField.value = user.name;
          }
          return;
        case 'today':
          formField.value = new Date(Date.now()).toJSON(); // Using Date.now() makes mocking easier
          return;
        default:
          console.error(
            `Unsupported dynamic default value for key:"${formField.key}", defaultValue: "${formField.defaultOptions?.value}"`,
          );
          return;
      }
    }
  };
}
