/* eslint-disable @typescript-eslint/naming-convention */
import { Duration } from 'date-fns';

export type TimeDuration = Duration;

export enum TimeSpanKind {
  Relative = 'Relative',
  Absolute = 'Absolute',
}

export enum VisualizationType {
  Pareto = 'Pareto',
  Kanban = 'Kanban',
  Bar = 'Bar',
  Pie = 'Pie',
  Area = 'Area',
  Line = 'Line',
}
export enum TimeOperators {
  Add = 'Add',
  Subtract = 'Subtract',
}

interface AbsoluteTimeSpan {
  kind: TimeSpanKind.Absolute;
  start: Date;
  end: Date;
}

export interface RelativeTime {
  // An empty duration is equivalent to time === NOW.
  duration?: TimeDuration;
  operator: TimeOperators;
}

interface RelativeTimeSpan {
  kind: TimeSpanKind.Relative;
  start: RelativeTime;
  end: RelativeTime;
}

export type TimeSpan = AbsoluteTimeSpan | RelativeTimeSpan;

export function isAbsoluteTimeSpan(timeSpan: TimeSpan): timeSpan is AbsoluteTimeSpan {
  return timeSpan?.kind === TimeSpanKind.Absolute;
}

export function isRelativeTimeSpan(timeSpan: TimeSpan): timeSpan is RelativeTimeSpan {
  return timeSpan?.kind === TimeSpanKind.Relative;
}

export function isTimeSpan(timeSpan: TimeSpan): timeSpan is TimeSpan {
  return isAbsoluteTimeSpan(timeSpan) || isRelativeTimeSpan(timeSpan);
}

export interface TimeFilter {
  field: string;
  timeSpan: TimeSpan;
}

export interface VisualisationsContainer {
  _id: string;
  title: string;
  teamPath: string;
  visualisations: Visualisation[];
  timeFilter: TimeFilter;
  refresh: string;
}

export enum MetricType {
  /**
   * Generates an aggregation pipeline for MongoDB to count the number of records
   */
  Count = 'Count',
  /**
   * Mean time to repair
   */
  MTTR = 'MTTR',
  /**
   * Mean time between failure
   */
  MTBF = 'MTBF',
  /**
   * Operational Time
   */
  OperationalTime = 'OperationalTime',
  /**
   * Down Time
   */
  Downtime = 'Downtime',
}

export enum TimeBucketInterval {
  Month = 'Month',
  Week = 'Week',
  Day = 'Day',
}

export enum BucketKind {
  TimeBucket = 'TimeBucket',
  FieldBucket = 'FieldBucket',
}

export interface FieldBucket {
  kind: BucketKind.FieldBucket;
  metricType: MetricType;
  field: string;
}

export interface TimeBucket {
  kind: BucketKind.TimeBucket;
  metricType: MetricType;
  interval: TimeBucketInterval;
}

interface BaseConfig {
  title: string;
  formTypeId: string;
}

export type KanbanConfig = BaseConfig & {
  kind: VisualizationType.Kanban;
  formTypeId: string;
};

export type BarChartConfig = BaseConfig & {
  kind: VisualizationType.Bar;
  yAxisLabel: string;
  bucket: TimeBucket | FieldBucket;
  xAxisLabel: string;
  formTypeId: string;
};

export type ParetoConfig = BaseConfig & {
  kind: VisualizationType.Pareto;
  y1AxisLabel: string;
  y2AxisLabel: string;
  bucket: TimeBucket | FieldBucket;
  xAxisLabel: string;
  formTypeId: string;
};
export type PieConfig = BaseConfig & {
  kind: VisualizationType.Pie;
  bucket: FieldBucket;
  formTypeId: string;
};

interface Layout {
  cols: number;
  rows: number;
  y: number;
  x: number;
}

export type Visualisation = {
  _id?: string;
  layout: Layout;
  config: ParetoConfig | BarChartConfig | PieConfig | KanbanConfig; // or other config for other graphs
};

export type CreateVisualisationsContainerDto = Omit<VisualisationsContainer, '_id'>;
export type UpdateVisualisationsContainerDto = VisualisationsContainer;
export type DeleteVisualisationsContainerDto = Pick<VisualisationsContainer, '_id'>;

export type UpdateVisualisationsContainerResponseDto = CreateVisualisationsContainerResponseDto;

export interface CreateVisualisationsContainerResponseDto {
  payload: VisualisationsContainer;
  msg: string;
}
export interface GetVisualisationsContainersResponseDto {
  payload: VisualisationsContainer[];
  msg: string;
}
