import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, timer } from 'rxjs';
import { EntityTypeDto } from './models/entity-type-dto.model';
import { APIClient } from './api-client.service';
import { shareReplay, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

/*
  Generated class for the EntitiesProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

const CACHE_SIZE = 1;

const CACHE_INVALIDATE_TIME_MS = 1000 * 60 * 60; // 1 hour in ms

@Injectable()
export class EntitiesProvider {
  private cacheMap$: Map<string, Observable<EntityTypeDto>> = new Map();
  private cacheTimeMap$: Map<string, number> = new Map(); // new map to store timestamps

  private teamEntityTypesCache$: Map<string, Observable<EntityTypeDto[]>> = new Map<
    string,
    Observable<EntityTypeDto[]>
  >();
  constructor(private api: APIClient) {}

  // Method to clear both caches
  clearCache() {
    this.cacheMap$.clear();
    this.cacheTimeMap$.clear();
  }

  getEntityType(entityTypeId: string, refresh?: boolean): Observable<EntityTypeDto> {
    const now = Date.now();
    const cachedTime = this.cacheTimeMap$.get(entityTypeId) || 0;

    // Check if cache is older than 1 hour or if refresh is requested
    if (!this.cacheMap$.get(entityTypeId) || refresh || now - cachedTime > CACHE_INVALIDATE_TIME_MS) {
      const entityType = this.requestEntityType(entityTypeId).pipe(shareReplay(CACHE_SIZE));
      this.cacheMap$.set(entityTypeId, entityType);
      this.cacheTimeMap$.set(entityTypeId, now); // store the current time
    }
    return this.cacheMap$.get(entityTypeId).pipe(map((entity) => cloneDeep(entity)));
  }

  getDatchPublicEntities(): Observable<any> {
    return this.requestAllEntityTypes('/datch/public').pipe(
      map((datchPublicEntityArray: any) => {
        datchPublicEntityArray.forEach((entity) => {
          this.cacheMap$.set(entity._id, of(entity));
        });
        return datchPublicEntityArray;
      }),
    );
  }

  getAllEntityTypesByTeam(defaultTeam: string, refresh?: boolean): Observable<EntityTypeDto[]> {
    if (!this.teamEntityTypesCache$.get(defaultTeam) || refresh) {
      // Clear cache after timeout
      timer(CACHE_INVALIDATE_TIME_MS).subscribe({
        // use the complete call instead of next so it's picked up by the cache test (ensuring the observable completes)
        complete: () => this.teamEntityTypesCache$.delete(defaultTeam),
      });

      // Create cache observable
      const allEntityTypes$ = forkJoin(
        this.requestAllEntityTypes(defaultTeam),
        this.requestAllEntityTypes('/datch/public'),
      ).pipe(
        map(([defaultTeamEntityArray, datchPublicEntityArray]: [EntityTypeDto[], EntityTypeDto[]]) => {
          defaultTeamEntityArray.forEach((entity) => {
            this.cacheMap$.set(entity._id, of(entity));
          });

          datchPublicEntityArray.forEach((entity) => {
            this.cacheMap$.set(entity._id, of(entity));
          });
          return [...defaultTeamEntityArray, ...datchPublicEntityArray];
        }),
        shareReplay(CACHE_SIZE),
      );
      this.teamEntityTypesCache$.set(defaultTeam, allEntityTypes$);
    }
    return this.teamEntityTypesCache$.get(defaultTeam).pipe(map((entities) => cloneDeep(entities)));
  }

  private requestEntityType(entitiyTypeId: string) {
    return this.api.getEntitiesId({ id: entitiyTypeId }).pipe(map((entityType) => entityType.payload));
  }

  private requestAllEntityTypes(defaultTeam: string) {
    return this.api.getEntities({ teamPath: defaultTeam }).pipe(map((entityType) => entityType.payload));
  }
}
