import { Injectable } from '@angular/core';
import { LegendItem } from 'chart.js';
import { Subject } from 'rxjs';

@Injectable()
export class LegendFilterService {
  public legendClicked: Subject<{ legendItem: LegendItem }>;

  constructor() {
    this.legendClicked = new Subject();
  }
}
