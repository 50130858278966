import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { APIClient } from '.';
import { shareReplay, map } from 'rxjs/operators';
import { CreateVisualisationsContainerDto, VisualisationsContainer } from './models/visualisations-container.interface';

const CACHE_SIZE = 1;

@Injectable()
export class VisualisationsContainerService {
  private cacheMapVCs$: Map<string, Observable<VisualisationsContainer>> = new Map<
    string,
    Observable<VisualisationsContainer>
  >();

  constructor(private api: APIClient) {}

  getAllVisualContainers(teamPath: string): Observable<Array<VisualisationsContainer>> {
    return this.api.getVisualisationsContainers({ teamPath }).pipe(
      map((res) => res.payload),
      shareReplay(CACHE_SIZE),
      map((vcs: VisualisationsContainer[]) => {
        vcs.forEach((vc) => {
          this.cacheMapVCs$.set(vc._id, of(vc));
        });
        return vcs;
      }),
    );
  }
  getVisiualContainer(id: string, refresh?: boolean): Observable<VisualisationsContainer> {
    return this.cacheMapVCs$.get(id);
  }

  updateVC(vc: VisualisationsContainer): Observable<VisualisationsContainer> {
    return this.api.putVisualisationsContainers({ updateVCDto: vc }).pipe(
      map((res) => res.payload),
      map((vcr) => {
        this.cacheMapVCs$.set(vcr._id, of(vcr));
        return vcr;
      }),
    );
  }

  createVisualisationContainer(createVC: CreateVisualisationsContainerDto): Observable<VisualisationsContainer> {
    return this.api.postVisualisationsContainers({ createVCDto: createVC }).pipe(
      map((vc) => vc.payload),
      map((vc) => {
        this.cacheMapVCs$.set(vc._id, of(vc));
        return vc;
      }),
    );
  }

  deleteVisualisationContainer(id): Observable<any> {
    return this.api.deleteVisualisationsContainers({ id }).pipe(
      map((successfull) => {
        this.cacheMapVCs$.delete(id);
        return successfull;
      }),
    );
  }
}
