import * as ion from 'ionicons/icons';
import { NbIcons } from '@nebular/theme/components/icon/icon-pack';

function map(icon: string) {
  return icon.replace('data:image/svg+xml;utf8,', '');
}

export const NbIonIcons: NbIcons = {
  add: map(ion.add),
  'add-circle': map(ion.addCircle),
  'add-circle-outline': map(ion.addCircleOutline),
  'add-circle-sharp': map(ion.addCircleSharp),
  'add-outline': map(ion.addOutline),
  'add-sharp': map(ion.addSharp),
  airplane: map(ion.airplane),
  'airplane-outline': map(ion.airplaneOutline),
  'airplane-sharp': map(ion.airplaneSharp),
  alarm: map(ion.alarm),
  'alarm-outline': map(ion.alarmOutline),
  'alarm-sharp': map(ion.alarmSharp),
  albums: map(ion.albums),
  'albums-outline': map(ion.albumsOutline),
  'albums-sharp': map(ion.albumsSharp),
  alert: map(ion.alert),
  'alert-circle': map(ion.alertCircle),
  'alert-circle-outline': map(ion.alertCircleOutline),
  'alert-circle-sharp': map(ion.alertCircleSharp),
  'alert-outline': map(ion.alertOutline),
  'alert-sharp': map(ion.alertSharp),
  'american-football': map(ion.americanFootball),
  'american-football-outline': map(ion.americanFootballOutline),
  'american-football-sharp': map(ion.americanFootballSharp),
  analytics: map(ion.analytics),
  'analytics-outline': map(ion.analyticsOutline),
  'analytics-sharp': map(ion.analyticsSharp),
  aperture: map(ion.aperture),
  'aperture-outline': map(ion.apertureOutline),
  'aperture-sharp': map(ion.apertureSharp),
  apps: map(ion.apps),
  'apps-outline': map(ion.appsOutline),
  'apps-sharp': map(ion.appsSharp),
  archive: map(ion.archive),
  'archive-outline': map(ion.archiveOutline),
  'archive-sharp': map(ion.archiveSharp),
  'arrow-back': map(ion.arrowBack),
  'arrow-back-circle': map(ion.arrowBackCircle),
  'arrow-back-circle-outline': map(ion.arrowBackCircleOutline),
  'arrow-back-circle-sharp': map(ion.arrowBackCircleSharp),
  'arrow-back-outline': map(ion.arrowBackOutline),
  'arrow-back-sharp': map(ion.arrowBackSharp),
  'arrow-down': map(ion.arrowDown),
  'arrow-down-circle': map(ion.arrowDownCircle),
  'arrow-down-circle-outline': map(ion.arrowDownCircleOutline),
  'arrow-down-circle-sharp': map(ion.arrowDownCircleSharp),
  'arrow-down-outline': map(ion.arrowDownOutline),
  'arrow-down-sharp': map(ion.arrowDownSharp),
  'arrow-forward': map(ion.arrowForward),
  'arrow-forward-circle': map(ion.arrowForwardCircle),
  'arrow-forward-circle-outline': map(ion.arrowForwardCircleOutline),
  'arrow-forward-circle-sharp': map(ion.arrowForwardCircleSharp),
  'arrow-forward-outline': map(ion.arrowForwardOutline),
  'arrow-forward-sharp': map(ion.arrowForwardSharp),
  'arrow-redo': map(ion.arrowRedo),
  'arrow-redo-circle': map(ion.arrowRedoCircle),
  'arrow-redo-circle-outline': map(ion.arrowRedoCircleOutline),
  'arrow-redo-circle-sharp': map(ion.arrowRedoCircleSharp),
  'arrow-redo-outline': map(ion.arrowRedoOutline),
  'arrow-redo-sharp': map(ion.arrowRedoSharp),
  'arrow-undo': map(ion.arrowUndo),
  'arrow-undo-circle': map(ion.arrowUndoCircle),
  'arrow-undo-circle-outline': map(ion.arrowUndoCircleOutline),
  'arrow-undo-circle-sharp': map(ion.arrowUndoCircleSharp),
  'arrow-undo-outline': map(ion.arrowUndoOutline),
  'arrow-undo-sharp': map(ion.arrowUndoSharp),
  'arrow-up': map(ion.arrowUp),
  'arrow-up-circle': map(ion.arrowUpCircle),
  'arrow-up-circle-outline': map(ion.arrowUpCircleOutline),
  'arrow-up-circle-sharp': map(ion.arrowUpCircleSharp),
  'arrow-up-outline': map(ion.arrowUpOutline),
  'arrow-up-sharp': map(ion.arrowUpSharp),
  at: map(ion.at),
  'at-circle': map(ion.atCircle),
  'at-circle-outline': map(ion.atCircleOutline),
  'at-circle-sharp': map(ion.atCircleSharp),
  'at-outline': map(ion.atOutline),
  'at-sharp': map(ion.atSharp),
  attach: map(ion.attach),
  'attach-outline': map(ion.attachOutline),
  'attach-sharp': map(ion.attachSharp),
  backspace: map(ion.backspace),
  'backspace-outline': map(ion.backspaceOutline),
  'backspace-sharp': map(ion.backspaceSharp),
  bandage: map(ion.bandage),
  'bandage-outline': map(ion.bandageOutline),
  'bandage-sharp': map(ion.bandageSharp),
  'bar-chart': map(ion.barChart),
  'bar-chart-outline': map(ion.barChartOutline),
  'bar-chart-sharp': map(ion.barChartSharp),
  barbell: map(ion.barbell),
  'barbell-outline': map(ion.barbellOutline),
  'barbell-sharp': map(ion.barbellSharp),
  barcode: map(ion.barcode),
  'barcode-outline': map(ion.barcodeOutline),
  'barcode-sharp': map(ion.barcodeSharp),
  baseball: map(ion.baseball),
  'baseball-outline': map(ion.baseballOutline),
  'baseball-sharp': map(ion.baseballSharp),
  basket: map(ion.basket),
  'basket-outline': map(ion.basketOutline),
  'basket-sharp': map(ion.basketSharp),
  basketball: map(ion.basketball),
  'basketball-outline': map(ion.basketballOutline),
  'basketball-sharp': map(ion.basketballSharp),
  'battery-charging': map(ion.batteryCharging),
  'battery-charging-outline': map(ion.batteryChargingOutline),
  'battery-charging-sharp': map(ion.batteryChargingSharp),
  'battery-dead': map(ion.batteryDead),
  'battery-dead-outline': map(ion.batteryDeadOutline),
  'battery-dead-sharp': map(ion.batteryDeadSharp),
  'battery-full': map(ion.batteryFull),
  'battery-full-outline': map(ion.batteryFullOutline),
  'battery-full-sharp': map(ion.batteryFullSharp),
  'battery-half': map(ion.batteryHalf),
  'battery-half-outline': map(ion.batteryHalfOutline),
  'battery-half-sharp': map(ion.batteryHalfSharp),
  beaker: map(ion.beaker),
  'beaker-outline': map(ion.beakerOutline),
  'beaker-sharp': map(ion.beakerSharp),
  bed: map(ion.bed),
  'bed-outline': map(ion.bedOutline),
  'bed-sharp': map(ion.bedSharp),
  beer: map(ion.beer),
  'beer-outline': map(ion.beerOutline),
  'beer-sharp': map(ion.beerSharp),
  bicycle: map(ion.bicycle),
  'bicycle-outline': map(ion.bicycleOutline),
  'bicycle-sharp': map(ion.bicycleSharp),
  bluetooth: map(ion.bluetooth),
  'bluetooth-outline': map(ion.bluetoothOutline),
  'bluetooth-sharp': map(ion.bluetoothSharp),
  boat: map(ion.boat),
  'boat-outline': map(ion.boatOutline),
  'boat-sharp': map(ion.boatSharp),
  body: map(ion.body),
  'body-outline': map(ion.bodyOutline),
  'body-sharp': map(ion.bodySharp),
  bonfire: map(ion.bonfire),
  'bonfire-outline': map(ion.bonfireOutline),
  'bonfire-sharp': map(ion.bonfireSharp),
  book: map(ion.book),
  'book-outline': map(ion.bookOutline),
  'book-sharp': map(ion.bookSharp),
  bookmark: map(ion.bookmark),
  'bookmark-outline': map(ion.bookmarkOutline),
  'bookmark-sharp': map(ion.bookmarkSharp),
  bookmarks: map(ion.bookmarks),
  'bookmarks-outline': map(ion.bookmarksOutline),
  'bookmarks-sharp': map(ion.bookmarksSharp),
  briefcase: map(ion.briefcase),
  'briefcase-outline': map(ion.briefcaseOutline),
  'briefcase-sharp': map(ion.briefcaseSharp),
  browsers: map(ion.browsers),
  'browsers-outline': map(ion.browsersOutline),
  'browsers-sharp': map(ion.browsersSharp),
  brush: map(ion.brush),
  'brush-outline': map(ion.brushOutline),
  'brush-sharp': map(ion.brushSharp),
  bug: map(ion.bug),
  'bug-outline': map(ion.bugOutline),
  'bug-sharp': map(ion.bugSharp),
  build: map(ion.build),
  'build-outline': map(ion.buildOutline),
  'build-sharp': map(ion.buildSharp),
  bulb: map(ion.bulb),
  'bulb-outline': map(ion.bulbOutline),
  'bulb-sharp': map(ion.bulbSharp),
  bus: map(ion.bus),
  'bus-outline': map(ion.busOutline),
  'bus-sharp': map(ion.busSharp),
  business: map(ion.business),
  'business-outline': map(ion.businessOutline),
  'business-sharp': map(ion.businessSharp),
  cafe: map(ion.cafe),
  'cafe-outline': map(ion.cafeOutline),
  'cafe-sharp': map(ion.cafeSharp),
  calculator: map(ion.calculator),
  'calculator-outline': map(ion.calculatorOutline),
  'calculator-sharp': map(ion.calculatorSharp),
  calendar: map(ion.calendar),
  'calendar-outline': map(ion.calendarOutline),
  'calendar-sharp': map(ion.calendarSharp),
  call: map(ion.call),
  'call-outline': map(ion.callOutline),
  'call-sharp': map(ion.callSharp),
  camera: map(ion.camera),
  'camera-outline': map(ion.cameraOutline),
  'camera-reverse': map(ion.cameraReverse),
  'camera-reverse-outline': map(ion.cameraReverseOutline),
  'camera-reverse-sharp': map(ion.cameraReverseSharp),
  'camera-sharp': map(ion.cameraSharp),
  car: map(ion.car),
  'car-outline': map(ion.carOutline),
  'car-sharp': map(ion.carSharp),
  'car-sport': map(ion.carSport),
  'car-sport-outline': map(ion.carSportOutline),
  'car-sport-sharp': map(ion.carSportSharp),
  card: map(ion.card),
  'card-outline': map(ion.cardOutline),
  'card-sharp': map(ion.cardSharp),
  'caret-back': map(ion.caretBack),
  'caret-back-circle': map(ion.caretBackCircle),
  'caret-back-circle-outline': map(ion.caretBackCircleOutline),
  'caret-back-circle-sharp': map(ion.caretBackCircleSharp),
  'caret-back-outline': map(ion.caretBackOutline),
  'caret-back-sharp': map(ion.caretBackSharp),
  'caret-down': map(ion.caretDown),
  'caret-down-circle': map(ion.caretDownCircle),
  'caret-down-circle-outline': map(ion.caretDownCircleOutline),
  'caret-down-circle-sharp': map(ion.caretDownCircleSharp),
  'caret-down-outline': map(ion.caretDownOutline),
  'caret-down-sharp': map(ion.caretDownSharp),
  'caret-forward': map(ion.caretForward),
  'caret-forward-circle': map(ion.caretForwardCircle),
  'caret-forward-circle-outline': map(ion.caretForwardCircleOutline),
  'caret-forward-circle-sharp': map(ion.caretForwardCircleSharp),
  'caret-forward-outline': map(ion.caretForwardOutline),
  'caret-forward-sharp': map(ion.caretForwardSharp),
  'caret-up': map(ion.caretUp),
  'caret-up-circle': map(ion.caretUpCircle),
  'caret-up-circle-outline': map(ion.caretUpCircleOutline),
  'caret-up-circle-sharp': map(ion.caretUpCircleSharp),
  'caret-up-outline': map(ion.caretUpOutline),
  'caret-up-sharp': map(ion.caretUpSharp),
  cart: map(ion.cart),
  'cart-outline': map(ion.cartOutline),
  'cart-sharp': map(ion.cartSharp),
  cash: map(ion.cash),
  'cash-outline': map(ion.cashOutline),
  'cash-sharp': map(ion.cashSharp),
  cellular: map(ion.cellular),
  'cellular-outline': map(ion.cellularOutline),
  'cellular-sharp': map(ion.cellularSharp),
  chatbox: map(ion.chatbox),
  'chatbox-ellipses': map(ion.chatboxEllipses),
  'chatbox-ellipses-outline': map(ion.chatboxEllipsesOutline),
  'chatbox-ellipses-sharp': map(ion.chatboxEllipsesSharp),
  'chatbox-outline': map(ion.chatboxOutline),
  'chatbox-sharp': map(ion.chatboxSharp),
  chatbubble: map(ion.chatbubble),
  'chatbubble-ellipses': map(ion.chatbubbleEllipses),
  'chatbubble-ellipses-outline': map(ion.chatbubbleEllipsesOutline),
  'chatbubble-ellipses-sharp': map(ion.chatbubbleEllipsesSharp),
  'chatbubble-outline': map(ion.chatbubbleOutline),
  'chatbubble-sharp': map(ion.chatbubbleSharp),
  chatbubbles: map(ion.chatbubbles),
  'chatbubbles-outline': map(ion.chatbubblesOutline),
  'chatbubbles-sharp': map(ion.chatbubblesSharp),
  checkbox: map(ion.checkbox),
  'checkbox-outline': map(ion.checkboxOutline),
  'checkbox-sharp': map(ion.checkboxSharp),
  checkmark: map(ion.checkmark),
  'checkmark-circle': map(ion.checkmarkCircle),
  'checkmark-circle-outline': map(ion.checkmarkCircleOutline),
  'checkmark-circle-sharp': map(ion.checkmarkCircleSharp),
  'checkmark-done': map(ion.checkmarkDone),
  'checkmark-done-circle': map(ion.checkmarkDoneCircle),
  'checkmark-done-circle-outline': map(ion.checkmarkDoneCircleOutline),
  'checkmark-done-circle-sharp': map(ion.checkmarkDoneCircleSharp),
  'checkmark-done-outline': map(ion.checkmarkDoneOutline),
  'checkmark-done-sharp': map(ion.checkmarkDoneSharp),
  'checkmark-outline': map(ion.checkmarkOutline),
  'checkmark-sharp': map(ion.checkmarkSharp),
  'chevron-back': map(ion.chevronBack),
  'chevron-back-circle': map(ion.chevronBackCircle),
  'chevron-back-circle-outline': map(ion.chevronBackCircleOutline),
  'chevron-back-circle-sharp': map(ion.chevronBackCircleSharp),
  'chevron-back-outline': map(ion.chevronBackOutline),
  'chevron-back-sharp': map(ion.chevronBackSharp),
  'chevron-down': map(ion.chevronDown),
  'chevron-down-circle': map(ion.chevronDownCircle),
  'chevron-down-circle-outline': map(ion.chevronDownCircleOutline),
  'chevron-down-circle-sharp': map(ion.chevronDownCircleSharp),
  'chevron-down-outline': map(ion.chevronDownOutline),
  'chevron-down-sharp': map(ion.chevronDownSharp),
  'chevron-forward': map(ion.chevronForward),
  'chevron-forward-circle': map(ion.chevronForwardCircle),
  'chevron-forward-circle-outline': map(ion.chevronForwardCircleOutline),
  'chevron-forward-circle-sharp': map(ion.chevronForwardCircleSharp),
  'chevron-forward-outline': map(ion.chevronForwardOutline),
  'chevron-forward-sharp': map(ion.chevronForwardSharp),
  'chevron-up': map(ion.chevronUp),
  'chevron-up-circle': map(ion.chevronUpCircle),
  'chevron-up-circle-outline': map(ion.chevronUpCircleOutline),
  'chevron-up-circle-sharp': map(ion.chevronUpCircleSharp),
  'chevron-up-outline': map(ion.chevronUpOutline),
  'chevron-up-sharp': map(ion.chevronUpSharp),
  clipboard: map(ion.clipboard),
  'clipboard-outline': map(ion.clipboardOutline),
  'clipboard-sharp': map(ion.clipboardSharp),
  close: map(ion.close),
  'close-circle': map(ion.closeCircle),
  'close-circle-outline': map(ion.closeCircleOutline),
  'close-circle-sharp': map(ion.closeCircleSharp),
  'close-outline': map(ion.closeOutline),
  'close-sharp': map(ion.closeSharp),
  cloud: map(ion.cloud),
  'cloud-circle': map(ion.cloudCircle),
  'cloud-circle-outline': map(ion.cloudCircleOutline),
  'cloud-circle-sharp': map(ion.cloudCircleSharp),
  'cloud-done': map(ion.cloudDone),
  'cloud-done-outline': map(ion.cloudDoneOutline),
  'cloud-done-sharp': map(ion.cloudDoneSharp),
  'cloud-download': map(ion.cloudDownload),
  'cloud-download-outline': map(ion.cloudDownloadOutline),
  'cloud-download-sharp': map(ion.cloudDownloadSharp),
  'cloud-offline': map(ion.cloudOffline),
  'cloud-offline-outline': map(ion.cloudOfflineOutline),
  'cloud-offline-sharp': map(ion.cloudOfflineSharp),
  'cloud-outline': map(ion.cloudOutline),
  'cloud-sharp': map(ion.cloudSharp),
  'cloud-upload': map(ion.cloudUpload),
  'cloud-upload-outline': map(ion.cloudUploadOutline),
  'cloud-upload-sharp': map(ion.cloudUploadSharp),
  cloudy: map(ion.cloudy),
  'cloudy-night': map(ion.cloudyNight),
  'cloudy-night-outline': map(ion.cloudyNightOutline),
  'cloudy-night-sharp': map(ion.cloudyNightSharp),
  'cloudy-outline': map(ion.cloudyOutline),
  'cloudy-sharp': map(ion.cloudySharp),
  code: map(ion.code),
  'code-download': map(ion.codeDownload),
  'code-download-outline': map(ion.codeDownloadOutline),
  'code-download-sharp': map(ion.codeDownloadSharp),
  'code-outline': map(ion.codeOutline),
  'code-sharp': map(ion.codeSharp),
  'code-slash': map(ion.codeSlash),
  'code-slash-outline': map(ion.codeSlashOutline),
  'code-slash-sharp': map(ion.codeSlashSharp),
  'code-working': map(ion.codeWorking),
  'code-working-outline': map(ion.codeWorkingOutline),
  'code-working-sharp': map(ion.codeWorkingSharp),
  cog: map(ion.cog),
  'cog-outline': map(ion.cogOutline),
  'cog-sharp': map(ion.cogSharp),
  'color-fill': map(ion.colorFill),
  'color-fill-outline': map(ion.colorFillOutline),
  'color-fill-sharp': map(ion.colorFillSharp),
  'color-filter': map(ion.colorFilter),
  'color-filter-outline': map(ion.colorFilterOutline),
  'color-filter-sharp': map(ion.colorFilterSharp),
  'color-palette': map(ion.colorPalette),
  'color-palette-outline': map(ion.colorPaletteOutline),
  'color-palette-sharp': map(ion.colorPaletteSharp),
  'color-wand': map(ion.colorWand),
  'color-wand-outline': map(ion.colorWandOutline),
  'color-wand-sharp': map(ion.colorWandSharp),
  compass: map(ion.compass),
  'compass-outline': map(ion.compassOutline),
  'compass-sharp': map(ion.compassSharp),
  construct: map(ion.construct),
  'construct-outline': map(ion.constructOutline),
  'construct-sharp': map(ion.constructSharp),
  contract: map(ion.contract),
  'contract-outline': map(ion.contractOutline),
  'contract-sharp': map(ion.contractSharp),
  contrast: map(ion.contrast),
  'contrast-outline': map(ion.contrastOutline),
  'contrast-sharp': map(ion.contrastSharp),
  copy: map(ion.copy),
  'copy-outline': map(ion.copyOutline),
  'copy-sharp': map(ion.copySharp),
  create: map(ion.create),
  'create-outline': map(ion.createOutline),
  'create-sharp': map(ion.createSharp),
  crop: map(ion.crop),
  'crop-outline': map(ion.cropOutline),
  'crop-sharp': map(ion.cropSharp),
  cube: map(ion.cube),
  'cube-outline': map(ion.cubeOutline),
  'cube-sharp': map(ion.cubeSharp),
  cut: map(ion.cut),
  'cut-outline': map(ion.cutOutline),
  'cut-sharp': map(ion.cutSharp),
  desktop: map(ion.desktop),
  'desktop-outline': map(ion.desktopOutline),
  'desktop-sharp': map(ion.desktopSharp),
  disc: map(ion.disc),
  'disc-outline': map(ion.discOutline),
  'disc-sharp': map(ion.discSharp),
  document: map(ion.document),
  'document-attach': map(ion.documentAttach),
  'document-attach-outline': map(ion.documentAttachOutline),
  'document-attach-sharp': map(ion.documentAttachSharp),
  'document-outline': map(ion.documentOutline),
  'document-sharp': map(ion.documentSharp),
  'document-text': map(ion.documentText),
  'document-text-outline': map(ion.documentTextOutline),
  'document-text-sharp': map(ion.documentTextSharp),
  documents: map(ion.documents),
  'documents-outline': map(ion.documentsOutline),
  'documents-sharp': map(ion.documentsSharp),
  download: map(ion.download),
  'download-outline': map(ion.downloadOutline),
  'download-sharp': map(ion.downloadSharp),
  duplicate: map(ion.duplicate),
  'duplicate-outline': map(ion.duplicateOutline),
  'duplicate-sharp': map(ion.duplicateSharp),
  ear: map(ion.ear),
  'ear-outline': map(ion.earOutline),
  'ear-sharp': map(ion.earSharp),
  earth: map(ion.earth),
  'earth-outline': map(ion.earthOutline),
  'earth-sharp': map(ion.earthSharp),
  easel: map(ion.easel),
  'easel-outline': map(ion.easelOutline),
  'easel-sharp': map(ion.easelSharp),
  egg: map(ion.egg),
  'egg-outline': map(ion.eggOutline),
  'egg-sharp': map(ion.eggSharp),
  ellipse: map(ion.ellipse),
  'ellipse-outline': map(ion.ellipseOutline),
  'ellipse-sharp': map(ion.ellipseSharp),
  'ellipsis-horizontal': map(ion.ellipsisHorizontal),
  'ellipsis-horizontal-circle': map(ion.ellipsisHorizontalCircle),
  'ellipsis-horizontal-circle-outline': map(ion.ellipsisHorizontalCircleOutline),
  'ellipsis-horizontal-circle-sharp': map(ion.ellipsisHorizontalCircleSharp),
  'ellipsis-horizontal-outline': map(ion.ellipsisHorizontalOutline),
  'ellipsis-horizontal-sharp': map(ion.ellipsisHorizontalSharp),
  'ellipsis-vertical': map(ion.ellipsisVertical),
  'ellipsis-vertical-circle': map(ion.ellipsisVerticalCircle),
  'ellipsis-vertical-circle-outline': map(ion.ellipsisVerticalCircleOutline),
  'ellipsis-vertical-circle-sharp': map(ion.ellipsisVerticalCircleSharp),
  'ellipsis-vertical-outline': map(ion.ellipsisVerticalOutline),
  'ellipsis-vertical-sharp': map(ion.ellipsisVerticalSharp),
  enter: map(ion.enter),
  'enter-outline': map(ion.enterOutline),
  'enter-sharp': map(ion.enterSharp),
  exit: map(ion.exit),
  'exit-outline': map(ion.exitOutline),
  'exit-sharp': map(ion.exitSharp),
  expand: map(ion.expand),
  'expand-outline': map(ion.expandOutline),
  'expand-sharp': map(ion.expandSharp),
  eye: map(ion.eye),
  'eye-off': map(ion.eyeOff),
  'eye-off-outline': map(ion.eyeOffOutline),
  'eye-off-sharp': map(ion.eyeOffSharp),
  'eye-outline': map(ion.eyeOutline),
  'eye-sharp': map(ion.eyeSharp),
  eyedrop: map(ion.eyedrop),
  'eyedrop-outline': map(ion.eyedropOutline),
  'eyedrop-sharp': map(ion.eyedropSharp),
  'fast-food': map(ion.fastFood),
  'fast-food-outline': map(ion.fastFoodOutline),
  'fast-food-sharp': map(ion.fastFoodSharp),
  female: map(ion.female),
  'female-outline': map(ion.femaleOutline),
  'female-sharp': map(ion.femaleSharp),
  'file-tray': map(ion.fileTray),
  'file-tray-full': map(ion.fileTrayFull),
  'file-tray-full-outline': map(ion.fileTrayFullOutline),
  'file-tray-full-sharp': map(ion.fileTrayFullSharp),
  'file-tray-outline': map(ion.fileTrayOutline),
  'file-tray-sharp': map(ion.fileTraySharp),
  'file-tray-stacked': map(ion.fileTrayStacked),
  'file-tray-stacked-outline': map(ion.fileTrayStackedOutline),
  'file-tray-stacked-sharp': map(ion.fileTrayStackedSharp),
  film: map(ion.film),
  'film-outline': map(ion.filmOutline),
  'film-sharp': map(ion.filmSharp),
  filter: map(ion.filter),
  'filter-outline': map(ion.filterOutline),
  'filter-sharp': map(ion.filterSharp),
  'finger-print': map(ion.fingerPrint),
  'finger-print-outline': map(ion.fingerPrintOutline),
  'finger-print-sharp': map(ion.fingerPrintSharp),
  fitness: map(ion.fitness),
  'fitness-outline': map(ion.fitnessOutline),
  'fitness-sharp': map(ion.fitnessSharp),
  flag: map(ion.flag),
  'flag-outline': map(ion.flagOutline),
  'flag-sharp': map(ion.flagSharp),
  flame: map(ion.flame),
  'flame-outline': map(ion.flameOutline),
  'flame-sharp': map(ion.flameSharp),
  flash: map(ion.flash),
  'flash-off': map(ion.flashOff),
  'flash-off-outline': map(ion.flashOffOutline),
  'flash-off-sharp': map(ion.flashOffSharp),
  'flash-outline': map(ion.flashOutline),
  'flash-sharp': map(ion.flashSharp),
  flashlight: map(ion.flashlight),
  'flashlight-outline': map(ion.flashlightOutline),
  'flashlight-sharp': map(ion.flashlightSharp),
  flask: map(ion.flask),
  'flask-outline': map(ion.flaskOutline),
  'flask-sharp': map(ion.flaskSharp),
  flower: map(ion.flower),
  'flower-outline': map(ion.flowerOutline),
  'flower-sharp': map(ion.flowerSharp),
  folder: map(ion.folder),
  'folder-open': map(ion.folderOpen),
  'folder-open-outline': map(ion.folderOpenOutline),
  'folder-open-sharp': map(ion.folderOpenSharp),
  'folder-outline': map(ion.folderOutline),
  'folder-sharp': map(ion.folderSharp),
  football: map(ion.football),
  'football-outline': map(ion.footballOutline),
  'football-sharp': map(ion.footballSharp),
  funnel: map(ion.funnel),
  'funnel-outline': map(ion.funnelOutline),
  'funnel-sharp': map(ion.funnelSharp),
  'game-controller': map(ion.gameController),
  'game-controller-outline': map(ion.gameControllerOutline),
  'game-controller-sharp': map(ion.gameControllerSharp),
  gift: map(ion.gift),
  'gift-outline': map(ion.giftOutline),
  'gift-sharp': map(ion.giftSharp),
  'git-branch': map(ion.gitBranch),
  'git-branch-outline': map(ion.gitBranchOutline),
  'git-branch-sharp': map(ion.gitBranchSharp),
  'git-commit': map(ion.gitCommit),
  'git-commit-outline': map(ion.gitCommitOutline),
  'git-commit-sharp': map(ion.gitCommitSharp),
  'git-compare': map(ion.gitCompare),
  'git-compare-outline': map(ion.gitCompareOutline),
  'git-compare-sharp': map(ion.gitCompareSharp),
  'git-merge': map(ion.gitMerge),
  'git-merge-outline': map(ion.gitMergeOutline),
  'git-merge-sharp': map(ion.gitMergeSharp),
  'git-network': map(ion.gitNetwork),
  'git-network-outline': map(ion.gitNetworkOutline),
  'git-network-sharp': map(ion.gitNetworkSharp),
  'git-pull-request': map(ion.gitPullRequest),
  'git-pull-request-outline': map(ion.gitPullRequestOutline),
  'git-pull-request-sharp': map(ion.gitPullRequestSharp),
  glasses: map(ion.glasses),
  'glasses-outline': map(ion.glassesOutline),
  'glasses-sharp': map(ion.glassesSharp),
  globe: map(ion.globe),
  'globe-outline': map(ion.globeOutline),
  'globe-sharp': map(ion.globeSharp),
  golf: map(ion.golf),
  'golf-outline': map(ion.golfOutline),
  'golf-sharp': map(ion.golfSharp),
  grid: map(ion.grid),
  'grid-outline': map(ion.gridOutline),
  'grid-sharp': map(ion.gridSharp),
  hammer: map(ion.hammer),
  'hammer-outline': map(ion.hammerOutline),
  'hammer-sharp': map(ion.hammerSharp),
  'hand-left': map(ion.handLeft),
  'hand-left-outline': map(ion.handLeftOutline),
  'hand-left-sharp': map(ion.handLeftSharp),
  'hand-right': map(ion.handRight),
  'hand-right-outline': map(ion.handRightOutline),
  'hand-right-sharp': map(ion.handRightSharp),
  happy: map(ion.happy),
  'happy-outline': map(ion.happyOutline),
  'happy-sharp': map(ion.happySharp),
  'hardware-chip': map(ion.hardwareChip),
  'hardware-chip-outline': map(ion.hardwareChipOutline),
  'hardware-chip-sharp': map(ion.hardwareChipSharp),
  headset: map(ion.headset),
  'headset-outline': map(ion.headsetOutline),
  'headset-sharp': map(ion.headsetSharp),
  heart: map(ion.heart),
  'heart-circle': map(ion.heartCircle),
  'heart-circle-outline': map(ion.heartCircleOutline),
  'heart-circle-sharp': map(ion.heartCircleSharp),
  'heart-dislike': map(ion.heartDislike),
  'heart-dislike-circle': map(ion.heartDislikeCircle),
  'heart-dislike-circle-outline': map(ion.heartDislikeCircleOutline),
  'heart-dislike-circle-sharp': map(ion.heartDislikeCircleSharp),
  'heart-dislike-outline': map(ion.heartDislikeOutline),
  'heart-dislike-sharp': map(ion.heartDislikeSharp),
  'heart-half': map(ion.heartHalf),
  'heart-half-outline': map(ion.heartHalfOutline),
  'heart-half-sharp': map(ion.heartHalfSharp),
  'heart-outline': map(ion.heartOutline),
  'heart-sharp': map(ion.heartSharp),
  help: map(ion.help),
  'help-buoy': map(ion.helpBuoy),
  'help-buoy-outline': map(ion.helpBuoyOutline),
  'help-buoy-sharp': map(ion.helpBuoySharp),
  'help-circle': map(ion.helpCircle),
  'help-circle-outline': map(ion.helpCircleOutline),
  'help-circle-sharp': map(ion.helpCircleSharp),
  'help-outline': map(ion.helpOutline),
  'help-sharp': map(ion.helpSharp),
  home: map(ion.home),
  'home-outline': map(ion.homeOutline),
  'home-sharp': map(ion.homeSharp),
  hourglass: map(ion.hourglass),
  'hourglass-outline': map(ion.hourglassOutline),
  'hourglass-sharp': map(ion.hourglassSharp),
  'ice-cream': map(ion.iceCream),
  'ice-cream-outline': map(ion.iceCreamOutline),
  'ice-cream-sharp': map(ion.iceCreamSharp),
  image: map(ion.image),
  'image-outline': map(ion.imageOutline),
  'image-sharp': map(ion.imageSharp),
  images: map(ion.images),
  'images-outline': map(ion.imagesOutline),
  'images-sharp': map(ion.imagesSharp),
  infinite: map(ion.infinite),
  'infinite-outline': map(ion.infiniteOutline),
  'infinite-sharp': map(ion.infiniteSharp),
  information: map(ion.information),
  'information-circle': map(ion.informationCircle),
  'information-circle-outline': map(ion.informationCircleOutline),
  'information-circle-sharp': map(ion.informationCircleSharp),
  'information-outline': map(ion.informationOutline),
  'information-sharp': map(ion.informationSharp),
  journal: map(ion.journal),
  'journal-outline': map(ion.journalOutline),
  'journal-sharp': map(ion.journalSharp),
  key: map(ion.key),
  'key-outline': map(ion.keyOutline),
  'key-sharp': map(ion.keySharp),
  keypad: map(ion.keypad),
  'keypad-outline': map(ion.keypadOutline),
  'keypad-sharp': map(ion.keypadSharp),
  language: map(ion.language),
  'language-outline': map(ion.languageOutline),
  'language-sharp': map(ion.languageSharp),
  laptop: map(ion.laptop),
  'laptop-outline': map(ion.laptopOutline),
  'laptop-sharp': map(ion.laptopSharp),
  layers: map(ion.layers),
  'layers-outline': map(ion.layersOutline),
  'layers-sharp': map(ion.layersSharp),
  leaf: map(ion.leaf),
  'leaf-outline': map(ion.leafOutline),
  'leaf-sharp': map(ion.leafSharp),
  library: map(ion.library),
  'library-outline': map(ion.libraryOutline),
  'library-sharp': map(ion.librarySharp),
  link: map(ion.link),
  'link-outline': map(ion.linkOutline),
  'link-sharp': map(ion.linkSharp),
  list: map(ion.list),
  'list-circle': map(ion.listCircle),
  'list-circle-outline': map(ion.listCircleOutline),
  'list-circle-sharp': map(ion.listCircleSharp),
  'list-outline': map(ion.listOutline),
  'list-sharp': map(ion.listSharp),
  locate: map(ion.locate),
  'locate-outline': map(ion.locateOutline),
  'locate-sharp': map(ion.locateSharp),
  location: map(ion.location),
  'location-outline': map(ion.locationOutline),
  'location-sharp': map(ion.locationSharp),
  'lock-closed': map(ion.lockClosed),
  'lock-closed-outline': map(ion.lockClosedOutline),
  'lock-closed-sharp': map(ion.lockClosedSharp),
  'lock-open': map(ion.lockOpen),
  'lock-open-outline': map(ion.lockOpenOutline),
  'lock-open-sharp': map(ion.lockOpenSharp),
  'log-in': map(ion.logIn),
  'log-in-outline': map(ion.logInOutline),
  'log-in-sharp': map(ion.logInSharp),
  'log-out': map(ion.logOut),
  'log-out-outline': map(ion.logOutOutline),
  'log-out-sharp': map(ion.logOutSharp),
  'logo-amazon': map(ion.logoAmazon),
  'logo-amplify': map(ion.logoAmplify),
  'logo-android': map(ion.logoAndroid),
  'logo-angular': map(ion.logoAngular),
  'logo-apple': map(ion.logoApple),
  'logo-apple-appstore': map(ion.logoAppleAppstore),
  'logo-bitbucket': map(ion.logoBitbucket),
  'logo-bitcoin': map(ion.logoBitcoin),
  'logo-buffer': map(ion.logoBuffer),
  'logo-capacitor': map(ion.logoCapacitor),
  'logo-chrome': map(ion.logoChrome),
  'logo-closed-captioning': map(ion.logoClosedCaptioning),
  'logo-codepen': map(ion.logoCodepen),
  'logo-css-3': map(ion.logoCss3),
  'logo-designernews': map(ion.logoDesignernews),
  'logo-dribbble': map(ion.logoDribbble),
  'logo-dropbox': map(ion.logoDropbox),
  'logo-edge': map(ion.logoEdge),
  'logo-electron': map(ion.logoElectron),
  'logo-euro': map(ion.logoEuro),
  'logo-facebook': map(ion.logoFacebook),
  'logo-firebase': map(ion.logoFirebase),
  'logo-firefox': map(ion.logoFirefox),
  'logo-flickr': map(ion.logoFlickr),
  'logo-foursquare': map(ion.logoFoursquare),
  'logo-github': map(ion.logoGithub),
  'logo-google': map(ion.logoGoogle),
  'logo-google-playstore': map(ion.logoGooglePlaystore),
  'logo-hackernews': map(ion.logoHackernews),
  'logo-html-5': map(ion.logoHtml5),
  'logo-instagram': map(ion.logoInstagram),
  'logo-ionic': map(ion.logoIonic),
  'logo-ionitron': map(ion.logoIonitron),
  'logo-javascript': map(ion.logoJavascript),
  'logo-laravel': map(ion.logoLaravel),
  'logo-linkedin': map(ion.logoLinkedin),
  'logo-markdown': map(ion.logoMarkdown),
  'logo-no-smoking': map(ion.logoNoSmoking),
  'logo-nodejs': map(ion.logoNodejs),
  'logo-npm': map(ion.logoNpm),
  'logo-octocat': map(ion.logoOctocat),
  'logo-pinterest': map(ion.logoPinterest),
  'logo-playstation': map(ion.logoPlaystation),
  'logo-pwa': map(ion.logoPwa),
  'logo-python': map(ion.logoPython),
  'logo-react': map(ion.logoReact),
  'logo-reddit': map(ion.logoReddit),
  'logo-rss': map(ion.logoRss),
  'logo-sass': map(ion.logoSass),
  'logo-skype': map(ion.logoSkype),
  'logo-slack': map(ion.logoSlack),
  'logo-snapchat': map(ion.logoSnapchat),
  'logo-stackoverflow': map(ion.logoStackoverflow),
  'logo-steam': map(ion.logoSteam),
  'logo-stencil': map(ion.logoStencil),
  'logo-tumblr': map(ion.logoTumblr),
  'logo-tux': map(ion.logoTux),
  'logo-twitch': map(ion.logoTwitch),
  'logo-twitter': map(ion.logoTwitter),
  'logo-usd': map(ion.logoUsd),
  'logo-vimeo': map(ion.logoVimeo),
  'logo-vk': map(ion.logoVk),
  'logo-vue': map(ion.logoVue),
  'logo-web-component': map(ion.logoWebComponent),
  'logo-whatsapp': map(ion.logoWhatsapp),
  'logo-windows': map(ion.logoWindows),
  'logo-wordpress': map(ion.logoWordpress),
  'logo-xbox': map(ion.logoXbox),
  'logo-xing': map(ion.logoXing),
  'logo-yahoo': map(ion.logoYahoo),
  'logo-yen': map(ion.logoYen),
  'logo-youtube': map(ion.logoYoutube),
  magnet: map(ion.magnet),
  'magnet-outline': map(ion.magnetOutline),
  'magnet-sharp': map(ion.magnetSharp),
  mail: map(ion.mail),
  'mail-open': map(ion.mailOpen),
  'mail-open-outline': map(ion.mailOpenOutline),
  'mail-open-sharp': map(ion.mailOpenSharp),
  'mail-outline': map(ion.mailOutline),
  'mail-sharp': map(ion.mailSharp),
  'mail-unread': map(ion.mailUnread),
  'mail-unread-outline': map(ion.mailUnreadOutline),
  'mail-unread-sharp': map(ion.mailUnreadSharp),
  male: map(ion.male),
  'male-female': map(ion.maleFemale),
  'male-female-outline': map(ion.maleFemaleOutline),
  'male-female-sharp': map(ion.maleFemaleSharp),
  'male-outline': map(ion.maleOutline),
  'male-sharp': map(ion.maleSharp),
  man: map(ion.man),
  'man-outline': map(ion.manOutline),
  'man-sharp': map(ion.manSharp),
  map: map(ion.map),
  'map-outline': map(ion.mapOutline),
  'map-sharp': map(ion.mapSharp),
  medal: map(ion.medal),
  'medal-outline': map(ion.medalOutline),
  'medal-sharp': map(ion.medalSharp),
  medical: map(ion.medical),
  'medical-outline': map(ion.medicalOutline),
  'medical-sharp': map(ion.medicalSharp),
  medkit: map(ion.medkit),
  'medkit-outline': map(ion.medkitOutline),
  'medkit-sharp': map(ion.medkitSharp),
  megaphone: map(ion.megaphone),
  'megaphone-outline': map(ion.megaphoneOutline),
  'megaphone-sharp': map(ion.megaphoneSharp),
  menu: map(ion.menu),
  'menu-outline': map(ion.menuOutline),
  'menu-sharp': map(ion.menuSharp),
  mic: map(ion.mic),
  'mic-circle': map(ion.micCircle),
  'mic-circle-outline': map(ion.micCircleOutline),
  'mic-circle-sharp': map(ion.micCircleSharp),
  'mic-off': map(ion.micOff),
  'mic-off-circle': map(ion.micOffCircle),
  'mic-off-circle-outline': map(ion.micOffCircleOutline),
  'mic-off-circle-sharp': map(ion.micOffCircleSharp),
  'mic-off-outline': map(ion.micOffOutline),
  'mic-off-sharp': map(ion.micOffSharp),
  'mic-outline': map(ion.micOutline),
  'mic-sharp': map(ion.micSharp),
  moon: map(ion.moon),
  'moon-outline': map(ion.moonOutline),
  'moon-sharp': map(ion.moonSharp),
  move: map(ion.move),
  'move-outline': map(ion.moveOutline),
  'move-sharp': map(ion.moveSharp),
  'musical-note': map(ion.musicalNote),
  'musical-note-outline': map(ion.musicalNoteOutline),
  'musical-note-sharp': map(ion.musicalNoteSharp),
  'musical-notes': map(ion.musicalNotes),
  'musical-notes-outline': map(ion.musicalNotesOutline),
  'musical-notes-sharp': map(ion.musicalNotesSharp),
  navigate: map(ion.navigate),
  'navigate-circle': map(ion.navigateCircle),
  'navigate-circle-outline': map(ion.navigateCircleOutline),
  'navigate-circle-sharp': map(ion.navigateCircleSharp),
  'navigate-outline': map(ion.navigateOutline),
  'navigate-sharp': map(ion.navigateSharp),
  newspaper: map(ion.newspaper),
  'newspaper-outline': map(ion.newspaperOutline),
  'newspaper-sharp': map(ion.newspaperSharp),
  notifications: map(ion.notifications),
  'notifications-circle': map(ion.notificationsCircle),
  'notifications-circle-outline': map(ion.notificationsCircleOutline),
  'notifications-circle-sharp': map(ion.notificationsCircleSharp),
  'notifications-off': map(ion.notificationsOff),
  'notifications-off-circle': map(ion.notificationsOffCircle),
  'notifications-off-circle-outline': map(ion.notificationsOffCircleOutline),
  'notifications-off-circle-sharp': map(ion.notificationsOffCircleSharp),
  'notifications-off-outline': map(ion.notificationsOffOutline),
  'notifications-off-sharp': map(ion.notificationsOffSharp),
  'notifications-outline': map(ion.notificationsOutline),
  'notifications-sharp': map(ion.notificationsSharp),
  nuclear: map(ion.nuclear),
  'nuclear-outline': map(ion.nuclearOutline),
  'nuclear-sharp': map(ion.nuclearSharp),
  nutrition: map(ion.nutrition),
  'nutrition-outline': map(ion.nutritionOutline),
  'nutrition-sharp': map(ion.nutritionSharp),
  open: map(ion.open),
  'open-outline': map(ion.openOutline),
  'open-sharp': map(ion.openSharp),
  options: map(ion.options),
  'options-outline': map(ion.optionsOutline),
  'options-sharp': map(ion.optionsSharp),
  'paper-plane': map(ion.paperPlane),
  'paper-plane-outline': map(ion.paperPlaneOutline),
  'paper-plane-sharp': map(ion.paperPlaneSharp),
  'partly-sunny': map(ion.partlySunny),
  'partly-sunny-outline': map(ion.partlySunnyOutline),
  'partly-sunny-sharp': map(ion.partlySunnySharp),
  pause: map(ion.pause),
  'pause-circle': map(ion.pauseCircle),
  'pause-circle-outline': map(ion.pauseCircleOutline),
  'pause-circle-sharp': map(ion.pauseCircleSharp),
  'pause-outline': map(ion.pauseOutline),
  'pause-sharp': map(ion.pauseSharp),
  paw: map(ion.paw),
  'paw-outline': map(ion.pawOutline),
  'paw-sharp': map(ion.pawSharp),
  pencil: map(ion.pencil),
  'pencil-outline': map(ion.pencilOutline),
  'pencil-sharp': map(ion.pencilSharp),
  people: map(ion.people),
  'people-circle': map(ion.peopleCircle),
  'people-circle-outline': map(ion.peopleCircleOutline),
  'people-circle-sharp': map(ion.peopleCircleSharp),
  'people-outline': map(ion.peopleOutline),
  'people-sharp': map(ion.peopleSharp),
  person: map(ion.person),
  'person-add': map(ion.personAdd),
  'person-add-outline': map(ion.personAddOutline),
  'person-add-sharp': map(ion.personAddSharp),
  'person-circle': map(ion.personCircle),
  'person-circle-outline': map(ion.personCircleOutline),
  'person-circle-sharp': map(ion.personCircleSharp),
  'person-outline': map(ion.personOutline),
  'person-remove': map(ion.personRemove),
  'person-remove-outline': map(ion.personRemoveOutline),
  'person-remove-sharp': map(ion.personRemoveSharp),
  'person-sharp': map(ion.personSharp),
  'phone-landscape': map(ion.phoneLandscape),
  'phone-landscape-outline': map(ion.phoneLandscapeOutline),
  'phone-landscape-sharp': map(ion.phoneLandscapeSharp),
  'phone-portrait': map(ion.phonePortrait),
  'phone-portrait-outline': map(ion.phonePortraitOutline),
  'phone-portrait-sharp': map(ion.phonePortraitSharp),
  'pie-chart': map(ion.pieChart),
  'pie-chart-outline': map(ion.pieChartOutline),
  'pie-chart-sharp': map(ion.pieChartSharp),
  pin: map(ion.pin),
  'pin-outline': map(ion.pinOutline),
  'pin-sharp': map(ion.pinSharp),
  pint: map(ion.pint),
  'pint-outline': map(ion.pintOutline),
  'pint-sharp': map(ion.pintSharp),
  pizza: map(ion.pizza),
  'pizza-outline': map(ion.pizzaOutline),
  'pizza-sharp': map(ion.pizzaSharp),
  planet: map(ion.planet),
  'planet-outline': map(ion.planetOutline),
  'planet-sharp': map(ion.planetSharp),
  play: map(ion.play),
  'play-back': map(ion.playBack),
  'play-back-circle': map(ion.playBackCircle),
  'play-back-circle-outline': map(ion.playBackCircleOutline),
  'play-back-circle-sharp': map(ion.playBackCircleSharp),
  'play-back-outline': map(ion.playBackOutline),
  'play-back-sharp': map(ion.playBackSharp),
  'play-circle': map(ion.playCircle),
  'play-circle-outline': map(ion.playCircleOutline),
  'play-circle-sharp': map(ion.playCircleSharp),
  'play-forward': map(ion.playForward),
  'play-forward-circle': map(ion.playForwardCircle),
  'play-forward-circle-outline': map(ion.playForwardCircleOutline),
  'play-forward-circle-sharp': map(ion.playForwardCircleSharp),
  'play-forward-outline': map(ion.playForwardOutline),
  'play-forward-sharp': map(ion.playForwardSharp),
  'play-outline': map(ion.playOutline),
  'play-sharp': map(ion.playSharp),
  'play-skip-back': map(ion.playSkipBack),
  'play-skip-back-circle': map(ion.playSkipBackCircle),
  'play-skip-back-circle-outline': map(ion.playSkipBackCircleOutline),
  'play-skip-back-circle-sharp': map(ion.playSkipBackCircleSharp),
  'play-skip-back-outline': map(ion.playSkipBackOutline),
  'play-skip-back-sharp': map(ion.playSkipBackSharp),
  'play-skip-forward': map(ion.playSkipForward),
  'play-skip-forward-circle': map(ion.playSkipForwardCircle),
  'play-skip-forward-circle-outline': map(ion.playSkipForwardCircleOutline),
  'play-skip-forward-circle-sharp': map(ion.playSkipForwardCircleSharp),
  'play-skip-forward-outline': map(ion.playSkipForwardOutline),
  'play-skip-forward-sharp': map(ion.playSkipForwardSharp),
  podium: map(ion.podium),
  'podium-outline': map(ion.podiumOutline),
  'podium-sharp': map(ion.podiumSharp),
  power: map(ion.power),
  'power-outline': map(ion.powerOutline),
  'power-sharp': map(ion.powerSharp),
  pricetag: map(ion.pricetag),
  'pricetag-outline': map(ion.pricetagOutline),
  'pricetag-sharp': map(ion.pricetagSharp),
  pricetags: map(ion.pricetags),
  'pricetags-outline': map(ion.pricetagsOutline),
  'pricetags-sharp': map(ion.pricetagsSharp),
  print: map(ion.print),
  'print-outline': map(ion.printOutline),
  'print-sharp': map(ion.printSharp),
  pulse: map(ion.pulse),
  'pulse-outline': map(ion.pulseOutline),
  'pulse-sharp': map(ion.pulseSharp),
  push: map(ion.push),
  'push-outline': map(ion.pushOutline),
  'push-sharp': map(ion.pushSharp),
  'qr-code': map(ion.qrCode),
  'qr-code-outline': map(ion.qrCodeOutline),
  'qr-code-sharp': map(ion.qrCodeSharp),
  radio: map(ion.radio),
  'radio-button-off': map(ion.radioButtonOff),
  'radio-button-off-outline': map(ion.radioButtonOffOutline),
  'radio-button-off-sharp': map(ion.radioButtonOffSharp),
  'radio-button-on': map(ion.radioButtonOn),
  'radio-button-on-outline': map(ion.radioButtonOnOutline),
  'radio-button-on-sharp': map(ion.radioButtonOnSharp),
  'radio-outline': map(ion.radioOutline),
  'radio-sharp': map(ion.radioSharp),
  rainy: map(ion.rainy),
  'rainy-outline': map(ion.rainyOutline),
  'rainy-sharp': map(ion.rainySharp),
  reader: map(ion.reader),
  'reader-outline': map(ion.readerOutline),
  'reader-sharp': map(ion.readerSharp),
  receipt: map(ion.receipt),
  'receipt-outline': map(ion.receiptOutline),
  'receipt-sharp': map(ion.receiptSharp),
  recording: map(ion.recording),
  'recording-outline': map(ion.recordingOutline),
  'recording-sharp': map(ion.recordingSharp),
  refresh: map(ion.refresh),
  'refresh-circle': map(ion.refreshCircle),
  'refresh-circle-outline': map(ion.refreshCircleOutline),
  'refresh-circle-sharp': map(ion.refreshCircleSharp),
  'refresh-outline': map(ion.refreshOutline),
  'refresh-sharp': map(ion.refreshSharp),
  reload: map(ion.reload),
  'reload-circle': map(ion.reloadCircle),
  'reload-circle-outline': map(ion.reloadCircleOutline),
  'reload-circle-sharp': map(ion.reloadCircleSharp),
  'reload-outline': map(ion.reloadOutline),
  'reload-sharp': map(ion.reloadSharp),
  remove: map(ion.remove),
  'remove-circle': map(ion.removeCircle),
  'remove-circle-outline': map(ion.removeCircleOutline),
  'remove-circle-sharp': map(ion.removeCircleSharp),
  'remove-outline': map(ion.removeOutline),
  'remove-sharp': map(ion.removeSharp),
  'reorder-four': map(ion.reorderFour),
  'reorder-four-outline': map(ion.reorderFourOutline),
  'reorder-four-sharp': map(ion.reorderFourSharp),
  'reorder-three': map(ion.reorderThree),
  'reorder-three-outline': map(ion.reorderThreeOutline),
  'reorder-three-sharp': map(ion.reorderThreeSharp),
  'reorder-two': map(ion.reorderTwo),
  'reorder-two-outline': map(ion.reorderTwoOutline),
  'reorder-two-sharp': map(ion.reorderTwoSharp),
  repeat: map(ion.repeat),
  'repeat-outline': map(ion.repeatOutline),
  'repeat-sharp': map(ion.repeatSharp),
  resize: map(ion.resize),
  'resize-outline': map(ion.resizeOutline),
  'resize-sharp': map(ion.resizeSharp),
  restaurant: map(ion.restaurant),
  'restaurant-outline': map(ion.restaurantOutline),
  'restaurant-sharp': map(ion.restaurantSharp),
  'return-down-back': map(ion.returnDownBack),
  'return-down-back-outline': map(ion.returnDownBackOutline),
  'return-down-back-sharp': map(ion.returnDownBackSharp),
  'return-down-forward': map(ion.returnDownForward),
  'return-down-forward-outline': map(ion.returnDownForwardOutline),
  'return-down-forward-sharp': map(ion.returnDownForwardSharp),
  'return-up-back': map(ion.returnUpBack),
  'return-up-back-outline': map(ion.returnUpBackOutline),
  'return-up-back-sharp': map(ion.returnUpBackSharp),
  'return-up-forward': map(ion.returnUpForward),
  'return-up-forward-outline': map(ion.returnUpForwardOutline),
  'return-up-forward-sharp': map(ion.returnUpForwardSharp),
  ribbon: map(ion.ribbon),
  'ribbon-outline': map(ion.ribbonOutline),
  'ribbon-sharp': map(ion.ribbonSharp),
  rocket: map(ion.rocket),
  'rocket-outline': map(ion.rocketOutline),
  'rocket-sharp': map(ion.rocketSharp),
  rose: map(ion.rose),
  'rose-outline': map(ion.roseOutline),
  'rose-sharp': map(ion.roseSharp),
  sad: map(ion.sad),
  'sad-outline': map(ion.sadOutline),
  'sad-sharp': map(ion.sadSharp),
  save: map(ion.save),
  'save-outline': map(ion.saveOutline),
  'save-sharp': map(ion.saveSharp),
  scan: map(ion.scan),
  'scan-circle': map(ion.scanCircle),
  'scan-circle-outline': map(ion.scanCircleOutline),
  'scan-circle-sharp': map(ion.scanCircleSharp),
  'scan-outline': map(ion.scanOutline),
  'scan-sharp': map(ion.scanSharp),
  school: map(ion.school),
  'school-outline': map(ion.schoolOutline),
  'school-sharp': map(ion.schoolSharp),
  search: map(ion.search),
  'search-circle': map(ion.searchCircle),
  'search-circle-outline': map(ion.searchCircleOutline),
  'search-circle-sharp': map(ion.searchCircleSharp),
  'search-outline': map(ion.searchOutline),
  'search-sharp': map(ion.searchSharp),
  send: map(ion.send),
  'send-outline': map(ion.sendOutline),
  'send-sharp': map(ion.sendSharp),
  server: map(ion.server),
  'server-outline': map(ion.serverOutline),
  'server-sharp': map(ion.serverSharp),
  settings: map(ion.settings),
  'settings-outline': map(ion.settingsOutline),
  'settings-sharp': map(ion.settingsSharp),
  shapes: map(ion.shapes),
  'shapes-outline': map(ion.shapesOutline),
  'shapes-sharp': map(ion.shapesSharp),
  share: map(ion.share),
  'share-outline': map(ion.shareOutline),
  'share-sharp': map(ion.shareSharp),
  'share-social': map(ion.shareSocial),
  'share-social-outline': map(ion.shareSocialOutline),
  'share-social-sharp': map(ion.shareSocialSharp),
  shield: map(ion.shield),
  'shield-checkmark': map(ion.shieldCheckmark),
  'shield-checkmark-outline': map(ion.shieldCheckmarkOutline),
  'shield-checkmark-sharp': map(ion.shieldCheckmarkSharp),
  'shield-outline': map(ion.shieldOutline),
  'shield-sharp': map(ion.shieldSharp),
  shirt: map(ion.shirt),
  'shirt-outline': map(ion.shirtOutline),
  'shirt-sharp': map(ion.shirtSharp),
  shuffle: map(ion.shuffle),
  'shuffle-outline': map(ion.shuffleOutline),
  'shuffle-sharp': map(ion.shuffleSharp),
  skull: map(ion.skull),
  'skull-outline': map(ion.skullOutline),
  'skull-sharp': map(ion.skullSharp),
  snow: map(ion.snow),
  'snow-outline': map(ion.snowOutline),
  'snow-sharp': map(ion.snowSharp),
  speedometer: map(ion.speedometer),
  'speedometer-outline': map(ion.speedometerOutline),
  'speedometer-sharp': map(ion.speedometerSharp),
  square: map(ion.square),
  'square-outline': map(ion.squareOutline),
  'square-sharp': map(ion.squareSharp),
  star: map(ion.star),
  'star-half': map(ion.starHalf),
  'star-half-outline': map(ion.starHalfOutline),
  'star-half-sharp': map(ion.starHalfSharp),
  'star-outline': map(ion.starOutline),
  'star-sharp': map(ion.starSharp),
  'stats-chart': map(ion.statsChart),
  'stats-chart-outline': map(ion.statsChartOutline),
  'stats-chart-sharp': map(ion.statsChartSharp),
  stop: map(ion.stop),
  'stop-circle': map(ion.stopCircle),
  'stop-circle-outline': map(ion.stopCircleOutline),
  'stop-circle-sharp': map(ion.stopCircleSharp),
  'stop-outline': map(ion.stopOutline),
  'stop-sharp': map(ion.stopSharp),
  stopwatch: map(ion.stopwatch),
  'stopwatch-outline': map(ion.stopwatchOutline),
  'stopwatch-sharp': map(ion.stopwatchSharp),
  subway: map(ion.subway),
  'subway-outline': map(ion.subwayOutline),
  'subway-sharp': map(ion.subwaySharp),
  sunny: map(ion.sunny),
  'sunny-outline': map(ion.sunnyOutline),
  'sunny-sharp': map(ion.sunnySharp),
  'swap-horizontal': map(ion.swapHorizontal),
  'swap-horizontal-outline': map(ion.swapHorizontalOutline),
  'swap-horizontal-sharp': map(ion.swapHorizontalSharp),
  'swap-vertical': map(ion.swapVertical),
  'swap-vertical-outline': map(ion.swapVerticalOutline),
  'swap-vertical-sharp': map(ion.swapVerticalSharp),
  sync: map(ion.sync),
  'sync-circle': map(ion.syncCircle),
  'sync-circle-outline': map(ion.syncCircleOutline),
  'sync-circle-sharp': map(ion.syncCircleSharp),
  'sync-outline': map(ion.syncOutline),
  'sync-sharp': map(ion.syncSharp),
  'tablet-landscape': map(ion.tabletLandscape),
  'tablet-landscape-outline': map(ion.tabletLandscapeOutline),
  'tablet-landscape-sharp': map(ion.tabletLandscapeSharp),
  'tablet-portrait': map(ion.tabletPortrait),
  'tablet-portrait-outline': map(ion.tabletPortraitOutline),
  'tablet-portrait-sharp': map(ion.tabletPortraitSharp),
  tennisball: map(ion.tennisball),
  'tennisball-outline': map(ion.tennisballOutline),
  'tennisball-sharp': map(ion.tennisballSharp),
  terminal: map(ion.terminal),
  'terminal-outline': map(ion.terminalOutline),
  'terminal-sharp': map(ion.terminalSharp),
  text: map(ion.text),
  'text-outline': map(ion.textOutline),
  'text-sharp': map(ion.textSharp),
  thermometer: map(ion.thermometer),
  'thermometer-outline': map(ion.thermometerOutline),
  'thermometer-sharp': map(ion.thermometerSharp),
  'thumbs-down': map(ion.thumbsDown),
  'thumbs-down-outline': map(ion.thumbsDownOutline),
  'thumbs-down-sharp': map(ion.thumbsDownSharp),
  'thumbs-up': map(ion.thumbsUp),
  'thumbs-up-outline': map(ion.thumbsUpOutline),
  'thumbs-up-sharp': map(ion.thumbsUpSharp),
  thunderstorm: map(ion.thunderstorm),
  'thunderstorm-outline': map(ion.thunderstormOutline),
  'thunderstorm-sharp': map(ion.thunderstormSharp),
  time: map(ion.time),
  'time-outline': map(ion.timeOutline),
  'time-sharp': map(ion.timeSharp),
  timer: map(ion.timer),
  'timer-outline': map(ion.timerOutline),
  'timer-sharp': map(ion.timerSharp),
  today: map(ion.today),
  'today-outline': map(ion.todayOutline),
  'today-sharp': map(ion.todaySharp),
  toggle: map(ion.toggle),
  'toggle-outline': map(ion.toggleOutline),
  'toggle-sharp': map(ion.toggleSharp),
  'trail-sign': map(ion.trailSign),
  'trail-sign-outline': map(ion.trailSignOutline),
  'trail-sign-sharp': map(ion.trailSignSharp),
  train: map(ion.train),
  'train-outline': map(ion.trainOutline),
  'train-sharp': map(ion.trainSharp),
  transgender: map(ion.transgender),
  'transgender-outline': map(ion.transgenderOutline),
  'transgender-sharp': map(ion.transgenderSharp),
  trash: map(ion.trash),
  'trash-bin': map(ion.trashBin),
  'trash-bin-outline': map(ion.trashBinOutline),
  'trash-bin-sharp': map(ion.trashBinSharp),
  'trash-outline': map(ion.trashOutline),
  'trash-sharp': map(ion.trashSharp),
  'trending-down': map(ion.trendingDown),
  'trending-down-outline': map(ion.trendingDownOutline),
  'trending-down-sharp': map(ion.trendingDownSharp),
  'trending-up': map(ion.trendingUp),
  'trending-up-outline': map(ion.trendingUpOutline),
  'trending-up-sharp': map(ion.trendingUpSharp),
  triangle: map(ion.triangle),
  'triangle-outline': map(ion.triangleOutline),
  'triangle-sharp': map(ion.triangleSharp),
  trophy: map(ion.trophy),
  'trophy-outline': map(ion.trophyOutline),
  'trophy-sharp': map(ion.trophySharp),
  tv: map(ion.tv),
  'tv-outline': map(ion.tvOutline),
  'tv-sharp': map(ion.tvSharp),
  umbrella: map(ion.umbrella),
  'umbrella-outline': map(ion.umbrellaOutline),
  'umbrella-sharp': map(ion.umbrellaSharp),
  videocam: map(ion.videocam),
  'videocam-outline': map(ion.videocamOutline),
  'videocam-sharp': map(ion.videocamSharp),
  'volume-high': map(ion.volumeHigh),
  'volume-high-outline': map(ion.volumeHighOutline),
  'volume-high-sharp': map(ion.volumeHighSharp),
  'volume-low': map(ion.volumeLow),
  'volume-low-outline': map(ion.volumeLowOutline),
  'volume-low-sharp': map(ion.volumeLowSharp),
  'volume-medium': map(ion.volumeMedium),
  'volume-medium-outline': map(ion.volumeMediumOutline),
  'volume-medium-sharp': map(ion.volumeMediumSharp),
  'volume-mute': map(ion.volumeMute),
  'volume-mute-outline': map(ion.volumeMuteOutline),
  'volume-mute-sharp': map(ion.volumeMuteSharp),
  'volume-off': map(ion.volumeOff),
  'volume-off-outline': map(ion.volumeOffOutline),
  'volume-off-sharp': map(ion.volumeOffSharp),
  walk: map(ion.walk),
  'walk-outline': map(ion.walkOutline),
  'walk-sharp': map(ion.walkSharp),
  wallet: map(ion.wallet),
  'wallet-outline': map(ion.walletOutline),
  'wallet-sharp': map(ion.walletSharp),
  warning: map(ion.warning),
  'warning-outline': map(ion.warningOutline),
  'warning-sharp': map(ion.warningSharp),
  watch: map(ion.watch),
  'watch-outline': map(ion.watchOutline),
  'watch-sharp': map(ion.watchSharp),
  water: map(ion.water),
  'water-outline': map(ion.waterOutline),
  'water-sharp': map(ion.waterSharp),
  wifi: map(ion.wifi),
  'wifi-outline': map(ion.wifiOutline),
  'wifi-sharp': map(ion.wifiSharp),
  wine: map(ion.wine),
  'wine-outline': map(ion.wineOutline),
  'wine-sharp': map(ion.wineSharp),
  woman: map(ion.woman),
  'woman-outline': map(ion.womanOutline),
  'woman-sharp': map(ion.womanSharp),
};
