import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserDto } from '../data';
import { environment } from '../../../environments/environment';
import { AnalyticEventDto } from './analytics.interface';
import { UsersService } from '../data/users.service';
import { NavigationEnd, Router } from '@angular/router';

const TAG = 'AnalyticsService';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private initialised = false;
  private validUser$: Observable<UserDto>;
  constructor(private user: UsersService, private router: Router) {
    this.validUser$ = this.user.getUser().pipe(filter((usr) => !!usr?.auth_id));
    this.initAnalytics();
  }

  trackPageViews() {
    if (this.initialised) {
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        this.sendAnalytics({ name: '[PageView]' + this.router.url });
      });
    }
  }
  /**
   * Initialises the analytics package, binds any environmental data to the package.
   *
   * @returns boolean
   */
  private async initAnalytics(): Promise<void> {
    if (environment.enableAnalytics) {
      // Required for the smartlook integration for mixpanel to be available on the window object. Similar issue to what this article explains for sentry https://help.smartlook.com/en/articles/3244594-sentry
      (<any>window).mixpanel = mixpanel;
      await this.initMixPanelWrapper();
      this.initialised = true;
      const user = await this.validUser$.toPromise();
      this.attachUser(user);
    }
  }

  /**
   * Wraps the mixpanel.init method in a promise, returns when loaded function is called.
   *
   * @returns
   */
  private initMixPanelWrapper(): Promise<boolean> {
    return new Promise((resolve) => {
      mixpanel.init(environment.mixPanelAPIKey, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        api_host: 'https://api-eu.mixpanel.com',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        batch_requests: true,
        loaded: function () {
          return resolve(true);
        },
      });
    });
  }

  /**
   * Adds user specific information to the analytics session.
   *
   * @param user
   * @returns
   */
  private attachUser(user: UserDto) {
    if (!user) {
      throw new Error('Trying to initialise mix panel before user is loaded');
    }

    if (mixpanel.identify) mixpanel.identify(user.auth_id);
    else {
      throw new Error(`${TAG} mixpanel.identify doesn't exist`);
    }

    if (mixpanel.people?.set) {
      mixpanel.people.set({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        team: user?.prefs?.defaultTeam,
      });
    } else {
      throw new Error(`${TAG} mixpanel.people.set doesn't exist`);
    }
  }

  public sendAnalytics(event: AnalyticEventDto<any>): boolean {
    if (mixpanel.track && environment.enableAnalytics && this.initialised) {
      mixpanel.track(event.name, {
        ...event,
        env: environment.production ? 'production' : 'test',
      });
      return true;
    }
    return false;
  }
}
