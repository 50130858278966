import { KeycloakService } from 'keycloak-angular';
import { CompanyStorageService } from './company-storage.service';

export function initializer(keycloak: KeycloakService, storageService: CompanyStorageService): () => Promise<any> {
  return (): Promise<void> =>
    new Promise(async (resolve, reject) => {
      const companyInfo = storageService.getCompanyInfo();
      if (companyInfo) {
        try {
          keycloak.keycloakEvents$.subscribe((event) => {});
          await keycloak.init({
            config: companyInfo.authConfig,
            initOptions: {
              onLoad: 'check-sso',
              checkLoginIframe: false,
            },
            enableBearerInterceptor: true,
          });
          resolve();
        } catch (error) {
          storageService.clearCompanyInfo();
          reject(error);
        }
      } else {
        resolve();
      }
    });
}
