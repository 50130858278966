/**
 * Helper function for exhaustive checks when using discriminated unions
 *
 * @example
 *
 *    type foo = {type: 'a'};
 *    type bar = {type: 'b'};
 *    type Union = foo | bar;
 *
 *    function doSomething(arg: Union) {
 *      if (arg.type === 'a') {
 *        return something;
 *      }
 *
 *      if (arg.type === 'b') {
 *        return somethingElse;
 *      }
 *
 *      // Typescript will error if there are types in the union which have not been handled.
 *      return assertNever(arg);
 *    }
 */
export const assertNever = (value: never, throwError: boolean = false): never => {
  if (!throwError) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};

export default assertNever;
