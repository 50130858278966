import { Inject, Injectable } from '@angular/core';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { CompanyDto } from './models';

const STORAGE_KEY = 'companyInfo';
const SELECTOR_STORAGE_KEY = 'displayedCompanyInfo';

@Injectable()
export class CompanyStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public setCompanyInfo(companyInfo: CompanyDto) {
    this.storage.set(STORAGE_KEY, companyInfo);
    const selectorVal: CompanyDto[] = this.storage.get(SELECTOR_STORAGE_KEY) || [];
    if (!selectorVal?.find((company: CompanyDto) => company?.authConfig?.realm === companyInfo?.authConfig?.realm)) {
      selectorVal.push(companyInfo);
      this.storage.set(SELECTOR_STORAGE_KEY, selectorVal);
    }
  }

  getCompanyInfo(): CompanyDto {
    return this.storage.get(STORAGE_KEY);
  }

  getCompanySelectionOptions(): CompanyDto[] {
    return this.storage.get(SELECTOR_STORAGE_KEY);
  }

  clearCompanyInfo() {
    this.storage.remove(STORAGE_KEY);
  }

  clearAll() {
    return this.storage.clear();
  }
}
