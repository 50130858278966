import { ResourceReference } from '@datch/ngx-resources-ms-client';
import { EntityDto } from '../../@core/data/models';
import { DefaultOptions, RequiredOn } from '../../@core/data/models/field-type-dto.model';
import { ResourceMap } from '../../@core/data/resources.service';

export class FormItemBase<T> {
  value: T;
  key: string;
  label: string;
  required?: RequiredOn;
  hidden?: boolean;
  controlType: string;
  isSummaryField: boolean;
  payload?: { [key: string]: any };
  entities?: EntityDto[] = [];
  resources?: ResourceReference[] = [];
  resourcesResolved?: ResourceMap = {};
  previousValue?: T;
  defaultOptions?: DefaultOptions;
  callback?: () => void;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: RequiredOn;
      controlType?: string;
      isSummaryField?: boolean;
      payload?: object;
      entities?: EntityDto[];
      defaultOptions?: DefaultOptions;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = options.required || null;
    this.controlType = options.controlType || '';
    this.isSummaryField = options.isSummaryField || false;
    this.payload = options.payload || {};
    this.entities = options.entities || null;
    this.defaultOptions = options.defaultOptions || null;
  }
}
