import { Injectable } from '@angular/core';

@Injectable()
export class RoundingService {
  precision = 4;

  round(n: number): number {
    if (n) return Number(n.toPrecision(this.precision));
    else return n;
  }
}
