import { Injectable } from '@angular/core';
import { Observable, EMPTY, expand, reduce, timer, shareReplay, map, of, switchMap, take } from 'rxjs';
import {
  AssetV1,
  AssetsV1Service,
  GetManyAssetsResponseDto,
  GetManyLocationsResponseDto,
  LocationV1,
  LocationsV1Service,
  ResourceKind,
  ResourceReference,
} from '@datch/ngx-resources-ms-client';
import { Resource } from './models/resource.model';
import { cloneDeep } from 'lodash';
import { UsersService } from './users.service';

const CACHE_SIZE = 1;
const CACHE_INVALIDATE_TIME_MS = 1000 * 60 * 60; // 1 hour in ms

export type ResourceMap = { [id: string]: Resource };

@Injectable()
export class ResourcesService {
  private cacheGetAll$: Map<string, Observable<AssetV1[] | LocationV1[]>> = new Map();
  private cacheGetAllMap$: Map<string, Observable<ResourceMap>> = new Map();

  constructor(
    private locationsService: LocationsV1Service,
    private assetsService: AssetsV1Service,
    private usersService: UsersService,
  ) {}

  clearCache() {
    this.cacheGetAll$.clear();
    this.cacheGetAllMap$.clear();
  }

  private getTeamPath(): Observable<string> {
    return this.usersService.getUser().pipe(
      map((user) => user.prefs.defaultTeam),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getMany(kind: ResourceKind, ids: string[]): Observable<GetManyAssetsResponseDto | GetManyLocationsResponseDto> {
    return this.getTeamPath().pipe(
      take(1),
      switchMap((teamPath) => {
        switch (kind) {
          case 'asset':
            return this.assetsService.getMany({ teamPath, ids });
          case 'location':
            return this.locationsService.getMany({ teamPath, ids });
          default:
            throw new Error(`unsupported resource kind: ${kind}`);
        }
      }),
    );
  }

  getAllResponseToMap(response: AssetV1[] | LocationV1[]): ResourceMap {
    return (response as Resource[]).reduce((acc, res) => {
      acc[res._id] = res;
      return acc;
    }, {});
  }

  getAllMap(kind: ResourceKind, refresh?: boolean): Observable<ResourceMap> {
    return this.getTeamPath().pipe(
      take(1),
      switchMap((teamPath) => {
        const cacheKey = `${teamPath}:${kind}`;

        if (!this.cacheGetAllMap$.get(cacheKey) || refresh) {
          timer(CACHE_INVALIDATE_TIME_MS).subscribe({
            complete: () => {
              this.cacheGetAllMap$.delete(cacheKey);
            },
          });

          const mapped$ = this.getAll(kind).pipe(
            map((r) => this.getAllResponseToMap(r)),
            shareReplay(1), // do not refcount, this is important to not recompute
          );

          this.cacheGetAllMap$.set(cacheKey, mapped$);
        }

        return this.cacheGetAllMap$.get(cacheKey);
      }),
    );
  }

  getAll(kind: ResourceKind, refresh?: boolean): Observable<AssetV1[] | LocationV1[]> {
    return this.getTeamPath().pipe(
      take(1),
      switchMap((teamPath) => {
        const cacheKey = `${teamPath}:${kind}`;

        if (!this.cacheGetAll$.get(cacheKey) || refresh) {
          timer(CACHE_INVALIDATE_TIME_MS).subscribe({
            complete: () => this.cacheGetAll$.delete(cacheKey),
          });

          switch (kind) {
            case 'asset':
              const assets = this.assetsService.getNext({ teamPath }).pipe(
                expand((resp) => (resp.next ? this.assetsService.getNext({ next: resp.next, teamPath }) : EMPTY)),
                reduce((acc, resp) => acc.concat(resp.assets), []),
                shareReplay(CACHE_SIZE),
              );
              this.cacheGetAll$.set(cacheKey, assets);
              break;
            case 'location':
              const locations = this.locationsService.getNext({ teamPath }).pipe(
                expand((resp) => (resp.next ? this.locationsService.getNext({ next: resp.next, teamPath }) : EMPTY)),
                reduce((acc, resp) => acc.concat(resp.locations), []),
                shareReplay(CACHE_SIZE),
              );
              this.cacheGetAll$.set(cacheKey, locations);
              break;
            default:
              throw new Error(`unsupported resource kind: '${kind}'`);
          }
        }

        return this.cacheGetAll$.get(cacheKey).pipe(map((resources) => cloneDeep(resources)));
      }),
    );
  }

  getResourceId(ref: ResourceReference[]): string | null {
    return ref && ref.length !== 0 ? ref[0].id : null;
  }

  getResourceKind(formItemKey: string): ResourceKind {
    // This could use better detection. This will work for integrated forms or those we explicitly
    // create with these key names but not for those which were user-created which don't have set.
    return formItemKey === 'floc' ? 'location' : 'asset';
  }

  toResourceReference(formItemKey: string, field: AssetV1 | LocationV1): ResourceReference[] {
    return [{ id: field._id, kind: this.getResourceKind(formItemKey) }];
  }

  getDisplayName(resource: Resource): string {
    return resource ? `${resource.externalId}, ${resource.name}` : '';
  }

  getRefDisplayName(ref: ResourceReference[]): Observable<string> {
    if (!ref || ref.length === 0) return of('');
    return this.getAllMap(ref[0].kind).pipe(
      take(1),
      map((r) => r?.[ref[0].id]),
      map((r) => this.getDisplayName(r)),
    );
  }
}
