import * as moment_ from 'moment';
import {
  RelativeTime,
  TimeBucketInterval,
  TimeFilter,
  TimeOperators,
  TimeSpanKind,
} from '../data/models/visualisations-container.interface';
import { add, sub, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, isEqual, isSameDay } from 'date-fns';
import { TimeBucketAggregateResult } from '../data';

enum DEFAULT_TIME_FORMAT {
  Day = 'MMM D',
  Week = 'll',
  Month = 'MMM YYYY',
}

export function formatDateTime(dateTime: string, timeFormat: TimeBucketInterval) {
  const moment = moment_;
  return moment(dateTime).format(DEFAULT_TIME_FORMAT[timeFormat]);
}

export function getEpochDateRange(timeFilter: TimeFilter) {
  switch (timeFilter.timeSpan.kind) {
    case TimeSpanKind.Absolute:
      return {
        fromDate: new Date(timeFilter.timeSpan.start).valueOf(),
        toDate: new Date(timeFilter.timeSpan.end).valueOf(),
      };
    case TimeSpanKind.Relative:
      return {
        fromDate: getRelativeTime(timeFilter.timeSpan.start).valueOf(),
        toDate: getRelativeTime(timeFilter.timeSpan.end).valueOf(),
      };
    default:
      throw new Error('Invalid TimeSpanKind');
  }
}

function getRelativeTime(time: RelativeTime) {
  switch (time.operator) {
    case TimeOperators.Add:
      return add(new Date(), time.duration || {});
    case TimeOperators.Subtract:
      return sub(new Date(), time.duration || {});
    default:
      throw new Error('Invalid TimeOperator');
  }
}

/**
 * Returns an array of dates between the start and end dates based on the specified granularity.
 *
 * @param start The start date of the interval.
 * @param end The end date of the interval.
 * @param granularity The time bucket interval to use for generating the dates.
 * @returns An array of dates between the start and end dates based on the specified granularity.
 * @throws An error if an invalid time bucket interval is provided.
 */
function getIntervalDates(start: Date, end: Date, granularity: TimeBucketInterval): Date[] {
  switch (granularity) {
    case TimeBucketInterval.Day:
      return eachDayOfInterval({ start, end });
    case TimeBucketInterval.Week:
      return eachWeekOfInterval({ start, end });
    case TimeBucketInterval.Month:
      return eachMonthOfInterval({ start, end });
    default:
      throw new Error('Invalid TimeBucketInterval');
  }
}

/**
 * Normalizes an array of data by filling in missing dates with zero values.
 *
 * @param data The array of data to normalize.
 * @param interval The time bucket interval to use for normalization.
 * @returns The normalized array of data.
 */
export function normalizeData(
  data: TimeBucketAggregateResult[],
  fromDate: number,
  toDate: number,
  interval: TimeBucketInterval,
): any[] {
  const normalizedData = [];
  const startDate = new Date(fromDate);
  const endDate = new Date(toDate);
  const dates = getIntervalDates(startDate, endDate, interval);
  for (const date of dates) {
    const formattedDate = date;
    const found = data.find((d) => isSameDay(new Date(d.bucketStartDate), formattedDate));
    normalizedData.push({
      bucketStartDate: formattedDate,
      value: found ? found.value : 0,
    });
  }

  return normalizedData;
}
