import { Component, AfterViewInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { KeycloakService } from 'keycloak-angular';
import { ViewModeService, ViewModes } from '../@core/utils/view-mode.service';
import { CompanyStorageService } from '../@core/data/company-storage.service';
import { initializer } from '../@core/data/app-initilizer';
import { CompanyDto } from '../@core/data';
import { environment } from '../../environments/environment';
import { isRealmValid } from '../@core/utils/sanitizer';

const TAG = '[SplashscreenComponent]';

@Component({
  selector: 'ngx-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
})
export class SplashscreenComponent implements AfterViewInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private viewModeService: ViewModeService,
    public companyService: CompanyStorageService,
    public keycloakService: KeycloakService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    let companyInfo = this.companyService.getCompanyInfo();
    const realmQueryParam = this.getRealmQueryParam();

    if (this.isCompanyRealmUpdateRequired(companyInfo, realmQueryParam)) {
      this.companyService.clearCompanyInfo();
      companyInfo = this.companyService.getCompanyInfo(); // nullify companyInfo.
      this.keycloakService.logout();
      return;
    }

    if (!this.companyService.getCompanyInfo() && realmQueryParam) {
      this.saveCompanyInfo({
        authConfig: {
          clientId: environment.keycloak.clientId,
          url: environment.keycloak.url,
          realm: realmQueryParam,
        },
        authLoginOptions: {
          redirectUri: environment.dashboardUrl,
        },
        domain: realmQueryParam,
      });
      companyInfo = this.companyService.getCompanyInfo();
    }

    this.viewModeService.getViewMode().then((viewMode) => {
      if (viewMode) {
        if (viewMode === ViewModes.POPULATE || viewMode === ViewModes.ONLY_FORMS)
          this.router.navigate(['pages', 'forms', 'form-type']);
        else if (viewMode === ViewModes.RAISE || viewMode === ViewModes.ONLY_RAISE)
          this.router.navigate(['pages', 'quick-start']);
        else this.router.navigate(['pages', 'unauthorized']);
      } else {
        if (companyInfo && this.keycloakService.getKeycloakInstance()) {
          this.keycloakService.login(companyInfo.authLoginOptions);
        } else if (companyInfo) {
          initializer(this.keycloakService, this.companyService)()
            .then((initialised) => {
              this.keycloakService.login(companyInfo.authLoginOptions);
            })
            .catch((error) => {
              console.log(TAG, 'initialised error', error);
            });
        } else {
          this.router.navigate(['login-email']);
        }
      }
    });
  }

  /**
   * Compares the realm passed in against the company confnigurations realm. If they are both valid bot not equal, then company info realm update is required.
   *
   * @param companyInfo the company info object.
   * @param queryParamRealm the realm passed in via query params
   * @returns true if they are the same, false if they are different.
   */
  isCompanyRealmUpdateRequired(companyInfo: CompanyDto, queryParamRealm: string) {
    return companyInfo && queryParamRealm && companyInfo.authConfig.realm !== queryParamRealm;
  }

  /**
   * Gets the realm query param
   *
   * @returns
   */
  getRealmQueryParam() {
    const realm = new URLSearchParams(this.document.location.search).get('realm');
    if (isRealmValid(realm)) return realm;
    else {
      console.error(TAG, 'Invalid realm query param');
      return null;
    }
  }

  saveCompanyInfo(companyDto: CompanyDto) {
    this.companyService.setCompanyInfo(companyDto);
  }
}
