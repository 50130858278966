import { NgModule } from '@angular/core';
import { AttachAuthPipe } from './attach-auth/attach-auth.pipe';
import { HttpClientWithInteceptors } from './http.client.service.singleton';
import { GetPhotoSourcePipe } from './get-photo-source/get-photo-source.pipe';
import { GetResourceDisplayNamePipe } from './get-resource-display-name/get-resource-display-name.pipe';

@NgModule({
  declarations: [AttachAuthPipe, GetPhotoSourcePipe, GetResourceDisplayNamePipe],
  imports: [],
  exports: [AttachAuthPipe, GetPhotoSourcePipe, GetResourceDisplayNamePipe],
  // providers: [HttpClientWithInteceptors],
})
export class PipesModule {
  // static forChild() {
  //     return {
  //         ngModule: PipesModule,
  //     }
  // }

  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [HttpClientWithInteceptors],
    };
  }
  // constructor(@Optional() @SkipSelf() parentModule: PipesModule) {
  //     throwIfAlreadyLoaded(parentModule, 'PipesModule');
  // }
}
