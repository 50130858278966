import { cloneDeep } from 'lodash';
import { firstValueFrom, forkJoin, Observable, of, throwError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { FormItemBase } from '../../components/dynamic-form.component.ts/form-item-base';
import { normalizeData } from '../utils/date-time.service';
import { APIClient, FieldTypeDto } from './';
import { EntitiesProvider } from './entities.service';
import { CreateFormInstanceDto } from './models/create-form-instance-dto.model';
import { DeleteFormInstanceDto } from './models/delete-form-instance-dto.model';
import { FormTypeDto } from './models/form-type-dto.model';
import { UpdateFormInstanceDto } from './models/update-form-instance-dto.model';
import { MetricType, TimeBucketInterval } from './models/visualisations-container.interface';

const CACHE_SIZE = 1;
const CACHE_INVALIDATE_TIME_MS = 1000 * 60 * 60; // 1 hour in milliseconds

@Injectable()
export class FormsService {
  private cache$allFormTypes: Observable<Array<FormTypeDto>>;
  private cacheTimeAllFormTypes: number; // timestamp for all form types cache
  private cacheMap$FormTypes: Map<string, Observable<FormTypeDto>> = new Map();
  private cacheTimeMap$FormTypes: Map<string, number> = new Map(); // timestamps for individual form types
  private defaultFormStream: Observable<FormTypeDto>;

  constructor(private api: APIClient, private entities: EntitiesProvider) {}

  async getForm({ formInstanceId, formTypeId }: { formInstanceId: string; formTypeId: string }) {
    const [formType, formInstance]: [FormTypeDto, any] = await firstValueFrom(
      forkJoin([this.getFormTypeId(formTypeId), this.getFormInstance(formInstanceId, formTypeId)]),
    );

    const formItems: FormItemBase<any>[] = [];

    if (!formInstance['createdAt']) {
      formItems.push(
        // adds to beginning of array.
        new FormItemBase({
          value: formInstance['createdAt'],
          key: 'createdAt',
          label: 'Created',
          controlType: 'metadatetime',
          isSummaryField: false,
        }),
      );
    }

    // add the _id
    formItems.push({
      controlType: 'metadata',
      label: null,
      key: '_id',
      value: formInstance._id,
      isSummaryField: false,
    });

    for (const fieldType of formType.formFields) {
      const formField: FieldTypeDto & { value: any } = { ...structuredClone(fieldType), value: undefined };

      if (
        (formField.controlType === 'dropdown' ||
          formField.controlType === 'status' ||
          formField.controlType === 'multidropdown') &&
        (formField.entityTypeId || formField.payload?.entityTypeId)
      ) {
        const entityType = await firstValueFrom(
          this.entities.getEntityType(formField.entityTypeId || formField.payload?.entityTypeId),
        );
        formField['entities'] = entityType.entities;
      }

      if (formInstance[formField.key]) {
        formField['value'] = formInstance[formField.key];
      }

      formItems.push(new FormItemBase(formField));
    }

    return {
      formInstanceId,
      formTypeId,
      formType,
      formItems,
    };
  }

  getFormType(args: { teamPath: string }, refresh?: boolean): Observable<Array<FormTypeDto>> {
    const now = Date.now();
    if (!this.cache$allFormTypes || refresh || now - this.cacheTimeAllFormTypes > CACHE_INVALIDATE_TIME_MS) {
      this.cache$allFormTypes = this.requestFormType(args.teamPath).pipe(
        shareReplay(CACHE_SIZE),
        map((formtypes: FormTypeDto[]) => {
          formtypes.forEach((formType) => {
            this.cacheMap$FormTypes.set(formType._id, of(formType));
            this.cacheTimeMap$FormTypes.set(formType._id, now); // set timestamp for each form type
          });
          return formtypes;
        }),
      );
      this.cacheTimeAllFormTypes = now; // set timestamp for all form types
    }
    return this.cache$allFormTypes.pipe(map((formTypes) => cloneDeep(formTypes)));
  }

  getFormTypeId(id: string, refresh?: boolean) {
    const now = Date.now();
    const cachedTime = this.cacheTimeMap$FormTypes.get(id) || 0;

    if (!this.cacheMap$FormTypes.get(id) || refresh || now - cachedTime > CACHE_INVALIDATE_TIME_MS) {
      const formType = this.api.getFormTypeId({ id }).pipe(
        map((response) => response.payload),
        shareReplay(CACHE_SIZE),
      );
      this.cacheMap$FormTypes.set(id, formType);
      this.cacheTimeMap$FormTypes.set(id, now); // set timestamp for this form type
    }
    return this.cacheMap$FormTypes.get(id).pipe(map((formType) => cloneDeep(formType)));
  }

  // getFormInstance(args: { formTypeId: string }, refresh?: boolean): any {
  //     if (!this.cache$allFormTypes || refresh) {
  //         this.cache$allFormTypes = this.requestFormInstance(args.formTypeId).pipe(
  //             shareReplay(CACHE_SIZE),
  //         );
  //     }
  //     return this.cache$allFormTypes;
  // }

  setDefaultFormType(formType: FormTypeDto) {
    this.defaultFormStream = of(formType);
    // this.defaultFormStream.next(formType);
  }

  getDefaultFormType(): Observable<FormTypeDto> {
    if (this.defaultFormStream) return this.defaultFormStream;
    else
      return throwError({
        code: 'NoFormSet',
        msg: 'Default form not set',
      });
  }

  private requestFormType(teamPath) {
    return this.api.getFormType({ teamPath }).pipe(map((formType) => formType.payload));
  }

  getAllFormInstances(formTypeId: string) {
    return this.api.getFormInstance({ formTypeId }).pipe(map((formInstances) => formInstances.payload));
  }

  getFormInstance(formInstanceId: string, formTypeId: string) {
    return this.api
      .getFormInstanceFormInstanceId({ formInstanceId, formTypeId })
      .pipe(map((formInstance) => formInstance.payload));
  }

  updateFormInstance(updateFormInstanceDto: UpdateFormInstanceDto) {
    return this.api.putFormInstance({ updateFormInstanceDto }).pipe(map((formInstance) => formInstance.payload));
  }

  createFormInstance(createFormInstanceDto: CreateFormInstanceDto) {
    return this.api.postFormInstance({ createFormInstanceDto }).pipe(map((formInstance) => formInstance.payload));
  }

  archiveFormInstance(deleteFormInstanceDto: DeleteFormInstanceDto) {
    return this.api.deleteFormInstance(deleteFormInstanceDto).pipe(map((res) => res.payload));
  }

  getRepairTimingsByAggregateFieldMetric(args: {
    formTypeId: string; // Id for the form type which the form instances are required
    fieldKey: string; // Field to apply the aggregation to
    fromDate: number; // Date to run the query from
    toDate: number; // Date to run the query to
  }) {
    const { formTypeId, fromDate, toDate, fieldKey } = args;
    return this.api
      .getReliabilityMetricByAggregateField({ fieldKey, formTypeId, fromDate, toDate })
      .pipe(map((res) => res.payload));
  }

  getAggregateTimeBucketsMetric(args: {
    formTypeId: string; // Id for the form type which the form instances are required
    fromDate: number; // Date to run the query from
    toDate: number; // Date to run the query to
    metricType: MetricType; // Metric to apply to the data
    interval: TimeBucketInterval; // Interval to bucket the data by
  }) {
    const { formTypeId, fromDate, toDate, metricType, interval } = args;
    return this.api
      .getAggregateTimeBucketsMetric({
        formTypeId,
        fromDate,
        toDate,
        metricType,
        interval,
      })
      .pipe(
        map((res) => res.payload),
        map((data) => normalizeData(data, fromDate, toDate, interval)),
      );
  }

  getCountByAggregateFieldMetric(args: {
    formTypeId: string; // Id for the form type which the form instances are required
    fromDate: number; // Date to run the query from
    toDate: number; // Date to run the query to
    fieldKey: string; // Field to apply the aggregation to
  }) {
    const { formTypeId, fromDate, toDate, fieldKey } = args;

    return this.api
      .getCountByAggregateFieldMetric({
        formTypeId,
        fromDate,
        toDate,
        fieldKey,
      })
      .pipe(map((res) => res.payload));
  }
}
