/* tslint:disable */

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { isRealmValid } from '../utils/sanitizer';
import { AggregateReliabilityMetricResponse, DefaultHttpOptions, HttpOptions } from './';

import * as models from './models';
import { FieldAggregateResponseDto } from './models/field-aggregate-response-dto.model';
import {
  CreateVisualisationsContainerResponseDto,
  TimeBucketInterval,
  MetricType,
  UpdateVisualisationsContainerResponseDto,
} from './models/visualisations-container.interface';

export const USE_DOMAIN = new InjectionToken<string>('USE_DOMAIN');
export const USE_HTTP_OPTIONS = new InjectionToken<HttpOptions>('USE_HTTP_OPTIONS');

type APIHttpOptions = HttpOptions & {
  headers: HttpHeaders;
  params: HttpParams;
};

export interface CompanyApiResponse {
  authConfig: {
    url: string;
    realm: string;
  };
  authLoginOptions: {};
}

export type MetricError = {
  message: string;
  name: string;
  response: string;
  status: number;
};

const isMetricError = (metricQueryResponse): metricQueryResponse is MetricError => {
  return metricQueryResponse?.status;
};

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class APIClient {
  readonly options: APIHttpOptions;

  private readonly domain: string = `//${window.location.hostname}${
    window.location.port ? ':' + window.location.port : ''
  }`;

  constructor(
    private readonly http: HttpClient,
    @Optional() @Inject(USE_DOMAIN) domain: string,
    @Optional() @Inject(USE_HTTP_OPTIONS) options: DefaultHttpOptions,
  ) {
    if (domain) {
      this.domain = domain;
    }

    this.options = {
      headers: new HttpHeaders(options && options.headers ? options.headers : {}),
      params: new HttpParams(options && options.params ? options.params : {}),
      ...(options && options.reportProgress ? { reportProgress: options.reportProgress } : {}),
      ...(options && options.withCredentials ? { withCredentials: options.withCredentials } : {}),
    };
  }

  /**
   * Returns user information
   */
  getUsers(requestHttpOptions?: HttpOptions): Observable<models.ResponseUserDto> {
    const path = '/account/users';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserDto>('GET', path, options);
  }

  /**
   * Update an aspect of the user profile
   */
  putUsers(
    args: {
      updateUserDto: models.UpdateUserDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.ResponseUserDto> {
    const path = '/account/users';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseUserDto>('PUT', path, options, JSON.stringify(args.updateUserDto));
  }

  postPayment(
    args: {
      createCustomerDto: models.CreateCustomerDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/payment';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.createCustomerDto));
  }

  getMinAppVersion(requestHttpOptions?: HttpOptions): Observable<any> {
    const path = '/account/min-app-version';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('GET', path, options);
  }

  getCompanyCompanyDomain(
    args: {
      companyDomain: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<{
    payload: CompanyApiResponse;
    msg: string;
  }> {
    const path = `/account/company/${args.companyDomain}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('GET', path, options).pipe(
      map((response: { payload: CompanyApiResponse; msg: string }) => {
        if (
          response.payload &&
          response.payload.authConfig &&
          response.payload.authConfig.realm &&
          isRealmValid(response.payload.authConfig.realm)
        )
          return response;
        else {
          console.error('Realm received from getCompanyDomain is invalid');
          return {
            payload: null,
            msg: 'Invalid realm received',
          };
        }
      }),
    );
  }

  postFormType(
    args: {
      createFormTypeDto: models.CreateFormTypeDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-type';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.createFormTypeDto));
  }

  putFormType(
    args: {
      updateFormTypeDto: models.UpdateFormTypeDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-type';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('PUT', path, options, JSON.stringify(args.updateFormTypeDto));
  }

  getFormType(
    args: {
      teamPath: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-type';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.params = options.params.set('teamPath', String(args.teamPath));
    }
    return this.sendRequest<any>('GET', path, options);
  }

  getFormTypeId(
    args: {
      id: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = `/forms/form-type/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('GET', path, options);
  }

  putFormInstanceFunctionsCheckout(
    args: {
      formInstanceId: string; // Id for the form instance to checkout
      formTypeId: string; // Id of the form instance's form type
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-instance/functions/checkout';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('formInstanceId' in args) {
      options.params = options.params.set('FormInstanceId', String(args.formInstanceId));
    }
    if ('formTypeId' in args) {
      options.params = options.params.set('FormTypeId', String(args.formTypeId));
    }
    return this.sendRequest<any>('PUT', path, options);
  }

  postFormInstance(
    args: {
      createFormInstanceDto: models.CreateFormInstanceDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-instance';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.createFormInstanceDto));
  }

  getFormInstance(
    args: {
      formTypeId: string; // Id for the form type which the form instances are required
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-instance';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('formTypeId' in args) {
      options.params = options.params.set('FormTypeId', String(args.formTypeId));
    }
    return this.sendRequest<any>('GET', path, options);
  }

  putFormInstance(
    args: {
      updateFormInstanceDto: models.UpdateFormInstanceDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-instance';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('PUT', path, options, JSON.stringify(args.updateFormInstanceDto));
  }

  deleteFormInstance(
    args: {
      formTypeId: string; // Form Type Id
      formInstanceId: string; // Form Instance Id
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/form-instance';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('formTypeId' in args) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    if ('formInstanceId' in args) {
      options.params = options.params.set('formInstanceId', String(args.formInstanceId));
    }
    return this.sendRequest<any>('DELETE', path, options);
  }

  getFormInstanceFormInstanceId(
    args: {
      formInstanceId: string;
      formTypeId: string; // Id for the form type which the form instances are required
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = `/forms/form-instance/${args.formInstanceId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('formTypeId' in args) {
      options.params = options.params.set('FormTypeId', String(args.formTypeId));
    }
    return this.sendRequest<any>('GET', path, options);
  }

  getReliabilityMetricByAggregateField(
    args: {
      formTypeId: string; // Id for the form type which the form instances are required
      fromDate: number; // Date to run the query from
      toDate: number; // Date to run the query to
      fieldKey: string; // Field to apply the aggregation to
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<AggregateReliabilityMetricResponse> {
    const path = '/forms/metrics/aggregate-field/reliability';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if (args.formTypeId) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    if (args.fromDate) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if (args.toDate) {
      options.params = options.params.set('toDate', String(args.toDate));
    }
    if (args.fieldKey) {
      options.params = options.params.set('fieldKey', String(args.fieldKey));
    }
    return this.sendRequest<AggregateReliabilityMetricResponse>('GET', path, options);
  }

  getCountByAggregateFieldMetric(
    args: {
      formTypeId: string; // Id for the form type which the form instances are required
      fromDate: number; // Date to run the query from
      toDate: number; // Date to run the query to
      fieldKey: string; // Field to apply the aggregation to
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<FieldAggregateResponseDto> {
    const path = '/forms/metrics/aggregate-field/count';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if (args.formTypeId) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    if (args.fromDate) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if (args.toDate) {
      options.params = options.params.set('toDate', String(args.toDate));
    }
    if (args.fieldKey) {
      options.params = options.params.set('fieldKey', String(args.fieldKey));
    }
    return this.sendRequest<FieldAggregateResponseDto>('GET', path, options);
  }

  getAggregateTimeBucketsMetric(
    args: {
      formTypeId: string; // Id for the form type which the form instances are required
      fromDate: number; // Date to run the query from
      toDate: number; // Date to run the query to
      metricType: MetricType; // Metric to apply to the data
      interval?: TimeBucketInterval; // Interval to bucket the data by
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.TimeBucketAggregateResponseDto> {
    const path = '/forms/metrics/aggregate-time-buckets/count';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if (args.formTypeId) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    if (args.fromDate) {
      options.params = options.params.set('fromDate', String(args.fromDate));
    }
    if (args.toDate) {
      options.params = options.params.set('toDate', String(args.toDate));
    }
    if (args.interval) {
      options.params = options.params.set('interval', String(args.interval));
    }
    if (args.metricType) {
      options.params = options.params.set('metricType', String(args.metricType));
    }
    return this.sendRequest<models.TimeBucketAggregateResponseDto>('GET', path, options);
  }

  postDetectIntent(
    args: {
      detectIntentDto: models.DetectIntentDto;
      teamPath?: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/dialog/detect-intent';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.headers = options.headers.set('team-path', String(args.teamPath));
    }
    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.detectIntentDto));
  }

  postDetectEvent(
    args: {
      detectEventDto: models.DetectEventDto;
      teamPath?: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/dialog/detect-event';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.headers = options.headers.set('team-path', String(args.teamPath));
    }
    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.detectEventDto));
  }

  postFulfillment(
    args: {
      fulfillmentRequestDto: models.FulfillmentRequestDto;
      teamPath?: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.FulfillmentResponseDto> {
    const path = '/dialog/fulfillment';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.headers = options.headers.set('team-path', String(args.teamPath));
    }
    return this.sendRequest<models.FulfillmentResponseDto>(
      'POST',
      path,
      options,
      JSON.stringify(args.fulfillmentRequestDto),
    );
  }

  getEntitiesId(
    args: {
      id: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.ResponseEntityTypeDto> {
    const path = `/entities/entities/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.ResponseEntityTypeDto>('GET', path, options);
  }

  getEntities(
    args: {
      teamPath: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.ResponseEntityTypesDto> {
    const path = '/entities/entities';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.params = options.params.set('teamPath', String(args.teamPath));
    }
    return this.sendRequest<models.ResponseEntityTypesDto>('GET', path, options);
  }

  postPaginatedForms(
    args: {
      paginatedRequest: models.PaginatedFormsRequestDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.PaginatedFormsResponseDto> {
    const path = '/forms/form-instance/get';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.PaginatedFormsResponseDto>(
      'POST',
      path,
      options,
      JSON.stringify(args.paginatedRequest),
    );
  }

  postAbilities(
    args: {
      createAbilityDto: models.CreateAbilityDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/abilities';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('POST', path, options, JSON.stringify(args.createAbilityDto));
  }

  putAbilities(
    args: {
      updateAbilityDto: models.UpdateAbilityDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/forms/abilities';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('PUT', path, options, JSON.stringify(args.updateAbilityDto));
  }

  getAbilities(
    args: {
      teamPath: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.AbilityArrayResponseDto> {
    const path = '/forms/abilities';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.params = options.params.set('teamPath', String(args.teamPath));
    }
    return this.sendRequest<models.AbilityArrayResponseDto>('GET', path, options);
  }

  getAbilitiesId(
    args: {
      id: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.AbilityResponseDto> {
    const path = `/forms/abilities/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.AbilityResponseDto>('GET', path, options);
  }

  getVisualisationsContainers(
    args: {
      teamPath: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<models.VisualisationsContainersResponseDto> {
    const path = '/dashboard/visualisations-container';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('teamPath' in args) {
      options.params = options.params.set('teamPath', String(args.teamPath));
    }

    return this.sendRequest<models.VisualisationsContainersResponseDto>('GET', path, options);
  }

  deleteVisualisationsContainers(
    args: {
      id: string;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = `/dashboard/visualisations-container/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<any>('DELETE', path, options);
  }
  postVisualisationsContainers(
    args: {
      createVCDto: models.CreateVisualisationsContainerDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<CreateVisualisationsContainerResponseDto> {
    const path = '/dashboard/visualisations-container';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<CreateVisualisationsContainerResponseDto>(
      'POST',
      path,
      options,
      JSON.stringify(args.createVCDto),
    );
  }
  putVisualisationsContainers(
    args: {
      updateVCDto: models.UpdateVisualisationsContainerDto;
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<UpdateVisualisationsContainerResponseDto> {
    const path = '/dashboard/visualisations-container';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };
    return this.sendRequest<UpdateVisualisationsContainerResponseDto>(
      'PUT',
      path,
      options,
      JSON.stringify(args.updateVCDto),
    );
  }

  postFormInstanceDataUpload(
    args: {
      formInstanceId: string; // Id for the form instance which the data will be associated with
      formTypeId: string; // Id for the form type which the form instances are required
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/dashboard/form-instance/data/upload';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('formInstanceId' in args) {
      options.params = options.params.set('formInstanceId', String(args.formInstanceId));
    }
    if ('formTypeId' in args) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    return this.sendRequest<any>('POST', path, options);
  }

  getFormInstanceDataDownload(
    args: {
      resizeWidth?: number; // (optional) Optional: Width to scale the image to before downloading
      path: string; // Key and filename of the data to download
      formInstanceId: string; // Id for the form instance which the data is associated with
      formTypeId: string; // Id for the form type which the form instances are required
    },
    requestHttpOptions?: HttpOptions,
  ): Observable<any> {
    const path = '/dashboard/form-instance/data/download';
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('resizeWidth' in args) {
      options.params = options.params.set('resizeWidth', String(args.resizeWidth));
    }
    if ('path' in args) {
      options.params = options.params.set('path', String(args.path));
    }
    if ('formInstanceId' in args) {
      options.params = options.params.set('formInstanceId', String(args.formInstanceId));
    }
    if ('formTypeId' in args) {
      options.params = options.params.set('formTypeId', String(args.formTypeId));
    }
    return this.sendRequest<any>('GET', path, options);
  }

  private sendRequest<T>(method: string, path: string, options: HttpOptions, body?: any): Observable<T> {
    switch (method) {
      case 'DELETE':
        return this.http.delete<T>(`${this.domain}${path}`, options);
      case 'GET':
        return this.http.get<T>(`${this.domain}${path}`, options);
      case 'HEAD':
        return this.http.head<T>(`${this.domain}${path}`, options);
      case 'OPTIONS':
        return this.http.options<T>(`${this.domain}${path}`, options);
      case 'PATCH':
        return this.http.patch<T>(`${this.domain}${path}`, body, options);
      case 'POST':
        return this.http.post<T>(`${this.domain}${path}`, body, options);
      case 'PUT':
        return this.http.put<T>(`${this.domain}${path}`, body, options);
      default:
        console.error(`Unsupported request: ${method}`);
        return throwError(`Unsupported request: ${method}`);
    }
  }
}
