import assertNever from '../../utils/assertNever';
import { MetricType } from './visualisations-container.interface';

export interface ReliabilityMetricResult {
  recordCount: number;
  asset?: string; // this will be what ever field key is chosen to group by
  meanTimeToRepair: number;
  totalDowntime: number;
  timeSpanHours: number;
  totalOperationalTime: number;
  meanTimeBetweenFailures: number;
}

/**
 * Converts a `MetricType` enum value to its corresponding key in the `ReliabilityMetricResult` object.
 *
 * @param metricType The `MetricType` enum value to convert.
 * @returns The corresponding key in the `ReliabilityMetricResult` object.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function MetricTypeToReliabilityMetricKey(metricType: MetricType): keyof ReliabilityMetricResult {
  switch (metricType) {
    case MetricType.Downtime:
      return 'totalDowntime';
    case MetricType.Count:
      return 'recordCount';
    case MetricType.MTBF:
      return 'meanTimeBetweenFailures';
    case MetricType.MTTR:
      return 'meanTimeToRepair';
    case MetricType.OperationalTime:
      return 'totalOperationalTime';
    default:
      assertNever(metricType); // If there's a missing case, TypeScript will flag it.
  }
}

export interface AggregateReliabilityMetricResponse {
  payload: ReliabilityMetricResult[];
  msg: string;
}
