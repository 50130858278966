import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewModeService } from './view-mode.service';
import { DefaultValuesService } from './default-values.service';

const SERVICES = [ViewModeService, DefaultValuesService];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class UtilModule {
  static forRoot(): ModuleWithProviders<UtilModule> {
    return <ModuleWithProviders<UtilModule>>{
      ngModule: UtilModule,
      providers: [...SERVICES],
    };
  }
}
