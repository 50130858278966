import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router, protected keycloakAngular: KeycloakService, public snackBar: MatSnackBar) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.keycloakAngular.login().catch((e) => console.error(e));
        return;
      }

      const requiredRoles = route.data.roles;
      // console.log('roles are', this.roles);
      // console.log('state is', state);
      // console.log('Route data is', route);
      // console.log('Roles are: ', requiredRoles);
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        let granted: boolean = false;
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }

        if (!granted && route.url[0].path !== 'unauthorized') {
          this.snackBar.open(`Access to ${route.url[0].path} denied`, null, {
            duration: 2000,
            panelClass: 'center-snackbar',
          });
          this.router.navigateByUrl('/pages/unauthorized');
        }
        resolve(granted);
      }
    }).then((resolution: boolean) => {
      if (!resolution) {
        console.error('Permission denied');
      }
      return resolution;
    });
  }
}
