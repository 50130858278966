import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/**
 * This is a hack to get around HttpClient instance being created for every lazy loaded module that uses it.
 * Since Attach-Auth needs the HttpClient which is created in AppModule (as it has the interceptors attached for auth), this is loaded in appmodule with "forRoot()", which creates a singleton of this service.
 * See  https://angular.io/guide/singleton-services
 */
@Injectable()
export class HttpClientWithInteceptors {
  constructor(public httpClient: HttpClient) {}
  get(url, options) {
    return this.httpClient.get(url, options);
  }
}
