import { UsersService } from './users.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { HttpClient } from '@angular/common/http';
import { FormsService } from './forms.service';
import { EntitiesProvider } from './entities.service';
import { LegendFilterService } from './legend-filter.service';
import { VisualisationsContainerService } from './visualisations-container.service';
import { ResourcesService } from './resources.service';

const SERVICES = [
  // HttpClient,
  FormsService,
  EntitiesProvider,
  ResourcesService,
  UsersService,
  LegendFilterService,
  VisualisationsContainerService,
];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return <ModuleWithProviders<DataModule>>{
      ngModule: DataModule,
      providers: [...SERVICES],
    };
  }
}
